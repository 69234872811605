import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from '../../assets/logo/logo_2.png';
import { FaXmark } from "react-icons/fa6";
import { TfiAlignJustify } from "react-icons/tfi";
import { IoIosSearch } from "react-icons/io";
import { IoCartOutline } from "react-icons/io5";
import { MdOutlinePerson2 } from "react-icons/md";
import SearchIndex from '../search/SearchIndex';

function NavBar() {

    const [isOpen, setIsOpen] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const scrollToSection = (id, height) => {
        const section = document.getElementById(id);
        if (section) {
            const offset = section.getBoundingClientRect().top + window.pageYOffset - height;
            window.scrollTo({
                top: offset,
                behavior: 'smooth'
            });
        }
    };

    const handleNavigateAndScroll = (path, sectionId) => {
        navigate(path, { state: { scrollTo: sectionId } });
    };

    useEffect(() => {
        if (location.state && location.state.scrollTo) {
            scrollToSection(location.state.scrollTo, 0);
            navigate(location.pathname, { replace: true, state: {} });
        }
    }, [location, navigate]);

    return (
        <>
            <div className='px-[5%] xl:px-[8%] relative hidden lg:block capitalize'>
                <div className='z-50 absolute backdrop-blur-lg bg-gradient-to-b from-white/50 to-navbar/50 left-20 right-20 top-14 md:flex px-[5%] lg:h-[70px] rounded-full justify-between capitalize items-center py-3 lg:text-sm xl:text-base'>
                    <Link to="/" className="cursor-pointer">home</Link>
                    <span className="cursor-pointer" onClick={() => handleNavigateAndScroll('/', 'products')}>products</span>
                    <Link to="/about" className="cursor-pointer">about us</Link>
                    <Link to='/' className='z-50 backdrop-blur-lg bg-navbar rounded-full'>
                        <img className='lg:w-[100px] xl:w-[120px] p-3' src={logo} alt="brand logo" />
                    </Link>
                    <Link to="/tea-story" className="cursor-pointer">Tea Story</Link>
                    <span className="cursor-pointer" onClick={() => handleNavigateAndScroll('/', 'contact')}>contact us</span>
                    <div className='flex gap-2'>
                        <span className='bg-background2/10 border-white/70 border-[0.5px] p-2 rounded-full text-primary-light' onClick={openModal}><IoIosSearch /></span>
                        <Link to="/my-cart" className='bg-background2/10 border-white/70 border-[0.5px] p-2 rounded-full text-primary-light'><IoCartOutline /></Link>
                        <div className='bg-background2/10 border-white/70 border-[0.5px] p-2 rounded-full text-primary-light'><MdOutlinePerson2 /></div>
                    </div>
                </div>
            </div>
            <div className='flex flex-row justify-between items-center px-5 lg:px-0 py-2 lg:py-0'>
                <div className='block lg:hidden'>
                    <Link to='/'>
                        <img className='w-[50px]' src={logo} alt="brand logo" />
                    </Link>
                </div>
                <div className='flex gap-5 items-center'>
                    <div className='flex lg:gap-2 lg:hidden'>
                        <span className='bg-background2/10 border-white/70 border-[0.5px] p-2 rounded-full text-primary-light' onClick={openModal}><IoIosSearch /></span>
                        <Link to="/my-cart" className='bg-background2/10 border-white/70 border-[0.5px] p-2 rounded-full text-primary-light'><IoCartOutline /></Link>
                        <div className='bg-background2/10 border-white/70 border-[0.5px] p-2 rounded-full text-primary-light'><MdOutlinePerson2 /></div>
                    </div>
                    <div onClick={() => setIsOpen(!isOpen)} className='cursor-pointer block lg:hidden'>
                        <div className='flex justify-center items-center'>
                            {isOpen ? <FaXmark /> : <TfiAlignJustify />}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`block lg:hidden  w-full items-center bg-background absolute py-4 z-50  transition-all duration-700 ease-in ${isOpen ? 'top-13' : 'top-[-490px]'}`}>
                <ul className='w-full text-base font-medium text-center text-fontColor space-y-5 capitalize'>
                    <li onClick={() => setIsOpen(false)}>
                        <Link to="/">home</Link>
                    </li>
                    <li onClick={() => setIsOpen(false)}>
                        <span className="cursor-pointer" onClick={() => handleNavigateAndScroll('/', 'products')}>products</span>
                    </li>
                    <li onClick={() => setIsOpen(false)}>
                        <span className="cursor-pointer" onClick={() => handleNavigateAndScroll('/', 'about')}>about us</span>
                    </li>
                    <li onClick={() => setIsOpen(false)}>
                        <Link to="/tea-story">Tea Story</Link>
                    </li>
                    <li onClick={() => setIsOpen(false)}>
                        <span className="cursor-pointer" onClick={() => handleNavigateAndScroll('/', 'contact')}>contact us</span>
                    </li>
                </ul>
            </div>
            <SearchIndex isOpen={isModalOpen} onClose={closeModal} />
        </>
    );
}

export default NavBar;
