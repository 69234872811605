import React from 'react'
import logo from '../../assets/logo/brand-logo.png'
import facebook from '../../assets/social/Frame 1597885437.png'
import twitter from '../../assets/social/Frame 1597885438.png'
import instagram from '../../assets/social/Frame 1597885439.png'
import { Link } from 'react-router-dom'


function FooterIndex() {
    return (
        <>
            <div className='px-[5%] xl:px-[8%] py-10 lg:py-10 bg-background2'>
                <div className='flex flex-col lg:flex-row gap-8 justify-between text-white  capitalize text-xs lg:text-sm xl:text-base'>
                    <div className='col-span-3'>
                        <div className='flex mb-3 items-center'>
                            <img className='-ml-6' src={logo} alt="logo" />
                            <div className=''>
                                <h1 className='text-white text-2xl'>DENAD</h1>
                                <p className='text-[13px]'>Moments With DeNaD</p>
                            </div>
                        </div>
                        <div className='text-[11px] lg:text-[12px] xl:text-[14px] space-y-2 '>
                            <p className='mb-2 leading-[25px]'>KBMAHA TRADERS(OPC) PRIVATE LIMITED<br></br> Site No 24, HighWayCity,<br></br>sarkarsamakulam, Kovilpalayam,<br></br> Coimbatore 641107</p>
                            <p>+91 9787591023 | +91 9916355135</p>
                            <p className='lowercase'>denadkbm@gmail.com | reachus@denad.in </p>
                        </div>
                    </div>
                    <div className='col-span-3'>
                        <h2 className='text-xl mb-8'>Information</h2>
                        <div className='leading-8 text-[11px] lg:text-[12px] xl:text-[14px]'>
                            <p><Link to="/" className="cursor-pointer">home</Link></p>
                            <p> <Link to="/about" className="cursor-pointer">about us</Link></p>
                            <p>Products</p>
                            <p> <Link to="/tea-story" className="cursor-pointer">Tea Story</Link></p>
                            <p>Contact Us</p>
                        </div>
                    </div>
                    <div className='col-span-3'>
                        <h2 className='text-xl mb-8'>Payment</h2>
                        <div className='leading-8 text-[11px] lg:text-[12px] xl:text-[14px]'>
                            <p>Visa</p>
                            <p>MasterCard</p>
                            <p>Paypal</p>
                            <p>UPI</p>
                        </div>
                    </div>
                    <div className='col-span-3'>
                        <h2 className='text-xl mb-8'>Follow us on</h2>
                        <div className='flex gap-3'>
                            <img className='w-[30px] h-[30px]' src={facebook} alt="facebook" />
                            <img className='w-[30px] h-[30px]' src={twitter} alt="twitter" />
                            <img className='w-[30px] h-[30px]' src={instagram} alt="instagram" />
                        </div>
                    </div>
                </div>
                <div className='md:flex md:justify-end md:gap-5 text-white text-xs lg:text-sm xl:text-base mt-5' >
                    <p>Terms & Agreements</p>
                    <p>Privacy Policy</p>
                </div>
            </div>
        </>
    )
}

export default FooterIndex
