import { Button, Label, TextInput } from 'flowbite-react';
import React, { useEffect } from 'react';

const CreditCard = ({ isOpen, onClose, children }) => {

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" onClick={onClose}>
            <div className="rounded-lg shadow-lg w-11/12 md:w-1/3" onClick={(e) => e.stopPropagation()}>
                <div className="relative p-2">
                    <button
                        className="absolute top-0 -right-3 shadow-md text-primary-light bg-white px-2  hover:text-gray-900"
                        onClick={onClose}
                    >
                        &times;
                    </button>
                </div>
                <div className="bg-white rounded-b-[40px] rounded-tl-[40px] p-4">
                    <h2 className='text-primary-light font-medium text-base'>Credit or Debit card</h2>
                    <div className='w-full h-[1px] bg-gray-300 my-3'></div>
                    <form className="flex  flex-col gap-2">
                        <div>
                            <div className="mb-2 block">
                                <Label className='text-sm' htmlFor="card" value="Card Number" />
                            </div>
                            <TextInput className='w-full rounded-[0px]' id="card" type="text" placeholder="Enter Card Number" required />
                        </div>
                        <div className='flex justify-between'>
                            <div>
                                <div className="mb-2 block">
                                    <Label className='text-sm' htmlFor="fname" value="Nick Name" />
                                </div>
                                <TextInput className='w-full rounded-[0px]' id="fname" type="text" placeholder="Enter Nick Name" required />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label className='text-sm' htmlFor="expiry" value="Expiry Date" />
                                </div>
                                <TextInput className='w-full rounded-[0px]' id="expiry" type="text" placeholder="Enter Expiry Date" required />
                            </div>
                        </div>
                        <div className='flex justify-end'>
                            <Button className='bg-primary-light rounded-full capitalize' type="submit">Enter Card Details</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreditCard;
