import React from 'react';
import { IoIosArrowForward } from "react-icons/io";
import star from '../../assets/home/Star 1.png';
import star2 from '../../assets/home/Star 5.png';

function ProductCard({ item, showExploreButton, onExploreClick }) {
    return (
        <div className="carousel-item-container border-2 relative mx-5 lg:mx-7 xl:mx-10 h-[100%] px-5 pb-10 lg:pb-10 bg-background3 rounded-t-[30px] lg:rounded-t-[50px] rounded-bl-[30px] lg:rounded-bl-[50px]">
            <div className="flex justify-around group">
                <div className="absolute -top-24 ">
                    <img src={item.imgSrc} alt={item.alt} className="group-hover:scale-110 duration-300 object-contain h-[250px]" />
                </div>
                <div className='pt-44'>
                    <div className="flex justify-between items-center mb-3">
                        <p className="font-semibold capitalize ">{item.name}</p>
                        <p className="flex">
                            <img className="ms-1 w-[15px] h-[15px] md:w-[18px] md:h-[18px]" src={star} alt="star" />
                            <img className="ms-1 w-[15px] h-[15px] md:w-[18px] md:h-[18px]" src={star} alt="star" />
                            <img className="ms-1 w-[15px] h-[15px] md:w-[18px] md:h-[18px]" src={star} alt="star" />
                            <img className="ms-1 w-[15px] h-[15px] md:w-[18px] md:h-[18px]" src={star} alt="star" />
                            <img className="ms-1 w-[15px] h-[15px] md:w-[18px] md:h-[18px]" src={star2} alt="stars" />
                        </p>
                    </div>
                    <p className="text-paragraph text-[12px] xl:text-[13px]">{item.description}</p>
                    {item.mrp && item.discount && (
                        <div className='flex mt-5 text-[12px] xl:text-[17px]'>
                            <p className='line-through text-gray-400 me-3'>₹ {item.mrp}</p>
                            <p className='font-semibold text-background2 '>₹ {item.discount}</p>
                        </div>
                    )}
                </div>
                {showExploreButton ? (
                    <div className="absolute z-50 -bottom-4 -right-6">
                        <button
                            className="group-hover:scale-110 transition-all duration-300 flex items-center capitalize bg-background2 text-white ps-5 px-4 py-1 rounded-full text-sm"
                            onClick={onExploreClick}
                        >
                            explore <IoIosArrowForward className='ms-2' />
                        </button>
                    </div>
                ) : (
                    <div className="absolute z-50 -bottom-4 -right-4">
                        <button className="group-hover:scale-110 transition-all duration-300 flex items-center capitalize bg-background2 text-white px-4 py-1 rounded-full text-sm">
                            add to cart
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ProductCard;
