import React from 'react'
import { Gallery } from "react-grid-gallery";
import gallery1 from '../../assets/about/gallery/Rectangle 41.png'
import gallery2 from '../../assets/about/gallery/Rectangle 43.png'
import gallery3 from '../../assets/about/gallery/Rectangle 46.png'
import gallery4 from '../../assets/about/gallery/Rectangle 42.png'
import gallery5 from '../../assets/about/gallery/Rectangle 44.png'
import gallery6 from '../../assets/about/gallery/Rectangle 45.png'

function AboutGallery() {
    const images = [
        {
            src: gallery1,
            // width: 320,
            // height: 174,
            // isSelected: true,
            // caption: "After Rain (Jeshu John - designerspics.com)",
        },
        {
            src: gallery2,
            // width: 320,
            // height: 212,
            // tags: [
            //     { value: "Ocean", title: "Ocean" },
            //     { value: "People", title: "People" },
            // ],
            // alt: "Boats (Jeshu John - designerspics.com)",
        },
        {
            src: gallery3,
            // width: 320,
            // height: 212,
        },
        {
            src: gallery4,
            // width: 320,
            // height: 212,
        },
        {
            src: gallery5,
            // width: 320,
            // height: 212,
        },
        {
            src: gallery6,
            // width: 320,
            // height: 212,
        },
    ];
    return (
        <div className='p-5 pb-5'>
            <h2 className='capitalize text-2xl py-5 text-primary-light font-semibold'>gallery</h2>
            <div className='grid grid-cols-12 gap-5 mb-5'>
                <div className='col-span-12 md:col-span-6'>
                    <img className='w-full h-auto md:h-[120px] lg:h-[170px]' src={gallery1} alt="" />
                </div>
                <div className='col-span-12 md:col-span-2'>
                    <img className='w-full h-auto md:h-[120px] lg:h-[170px]' src={gallery2} alt="" />
                </div>
                <div className='col-span-12 md:col-span-4'>
                    <img className='w-full h-auto md:h-[120px] lg:h-[170px]' src={gallery3} alt="" />
                </div>
            </div>
            <div className='grid grid-cols-12 gap-5'>
                <div className='col-span-12 md:col-span-4'>
                    <img className='w-full h-auto md:h-[180px] lg:h-[230px] xl:h-[270px]' src={gallery4} alt="" />
                </div>
                <div className='col-span-12 md:col-span-4'>
                    <img className='w-full h-auto md:h-[180px] lg:h-[230px] xl:h-[270px]' src={gallery5} alt="" />
                </div>
                <div className='col-span-12 md:col-span-4'>
                    <img className='w-full h-auto md:h-[180px] lg:h-[230px] xl:h-[270px]' src={gallery6} alt="" />
                </div>
            </div>
        </div>
    )
}

export default AboutGallery
