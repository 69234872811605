import React, { useState } from 'react';
import MyAddressIndex from './myAddress/MyAddressIndex';
import PreviewProductsIndex from './previewProducts/PreviewProductsIndex';
import { GoDotFill } from 'react-icons/go';
import PaymentMethodIndex from './paymentMethod/PaymentMethodIndex';

function CheckOutIndex() {
    const [currentStep, setCurrentStep] = useState(3);

    const handleStepChange = (step) => {
        setCurrentStep(step);
    };

    const nextStep = () => {
        if (currentStep < 3) {
            setCurrentStep(currentStep + 1);
        }
    };

    return (
        <div className='relative'>
            <div className='bg-productBanner bg-no-repeat w-full bg-cover h-52 lg:h-[65vh] xl:h-[80vh] absolute -z-10'></div>
            <div className='px-[5%] xl:px-[8%] py-10 pt-[150px] lg:pt-[400px]'>
                <div className='bg-background3 p-8 rounded-3xl mb-10'>
                    <h2 className='text-center text-primary-light font-bold text-lg lg:text-2xl'>Check Out</h2>
                    <div className='grid grid-cols-12'>
                        <div className='col-span-12 lg:col-span-8 p-5'>
                            <div className='grid grid-cols-12 '>
                                {/* step 1 */}
                                <div className='col-span-12 lg:col-span-2 flex flex-col justify-center items-center'>
                                    <div className='bg-primary-light text-white rounded-full w-8 h-8 flex justify-center items-center'>
                                        1
                                    </div>
                                    <div className={`h-full w-[5px] ${currentStep >= 2 ? 'bg-primary-light' : 'bg-gray-300'}`}></div>
                                </div>
                                <div className='col-span-12 lg:col-span-10'>
                                    <div id="shipping">
                                        <div className='flex justify-between items-start'>
                                            <h4 className='font-medium text-xl capitalize mb-5' onClick={() => handleStepChange(1)}>Shipping address</h4>
                                            {currentStep !== 1 && (
                                                <p className='capitalize underline text-primary-light change' onClick={() => handleStepChange(1)}>change</p>
                                            )}
                                        </div>
                                        {currentStep === 1 && (
                                            <>
                                                <MyAddressIndex nextStep={nextStep} />
                                            </>
                                        )}
                                        {currentStep !== 1 && (
                                            <div className='view flex'>
                                                <GoDotFill className='me-1 text-fontGreen' />
                                                <p className='text-sm'>Site no 24, HighWayCity, Sarkarsamakulam, Kovilpalayam, Coimbatore 641107</p>
                                            </div>
                                        )}
                                        <div className='w-full h-[1px] bg-gray-300 my-4'></div>
                                    </div>
                                </div>

                                {/* step 2 */}
                                <div className='col-span-12 lg:col-span-2 flex flex-col justify-center items-center'>
                                    <div className='bg-primary-light text-white rounded-full w-8 h-8 flex justify-center items-center'>
                                        2
                                    </div>
                                    <div className={`h-full w-[5px] ${currentStep === 3 ? 'bg-primary-light' : 'bg-gray-300'}`}></div>
                                </div>
                                <div className='col-span-12 lg:col-span-10 mb-5'>
                                    <div id='preview' className='capitalize text-lg'>
                                        <div className='flex justify-between items-start'>
                                            <h4 className='font-medium text-xl capitalize mb-5' onClick={() => handleStepChange(2)}>Preview products</h4>
                                            {currentStep !== 2 && (
                                                <p className='capitalize underline text-primary-light change' onClick={() => handleStepChange(2)}>change</p>
                                            )}
                                        </div>
                                        {currentStep === 2 && (
                                            <>
                                                <PreviewProductsIndex nextStep={nextStep} />
                                            </>
                                        )}
                                        {currentStep !== 2 && (
                                            <div className='view flex'>
                                                <GoDotFill className='me-1 text-fontGreen' />
                                                <p className='text-sm'>3 items</p>
                                            </div>
                                        )}
                                        <div className='w-full h-[1px] bg-gray-300 my-4'></div>
                                    </div>
                                </div>

                                {/* step 3 */}
                                <div className='col-span-12 lg:col-span-2 flex justify-center'>
                                    <div className='bg-primary-light text-white rounded-full w-8 h-8 flex justify-center items-center'>
                                        3
                                    </div>
                                </div>
                                <div className='col-span-12 lg:col-span-10'>
                                    <div id='payment' className='capitalize text-lg'>
                                        <div className='flex justify-between items-start'>
                                            <h4 className='font-medium text-xl capitalize mb-5' onClick={() => handleStepChange(3)}>Select payment method</h4>
                                            {currentStep !== 3 && (
                                                <p className='capitalize underline text-primary-light change' onClick={() => handleStepChange(3)}>change</p>
                                            )}
                                        </div>
                                        {currentStep === 3 && (
                                            <>
                                                <PaymentMethodIndex />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-span-12 lg:col-span-4 p-5'>
                            <div className='bg-white p-5 border-2 rounded-b-[40px] rounded-tr-[40px]'>
                                <p className='text-primary-light font-medium capitalize'>Order Summary</p>
                                <div className='w-full h-[1px] bg-gray-300 my-5'></div>
                                <div>
                                    <p className='capitalize mb-3 font-medium'>Cart Total</p>
                                    <div className='flex justify-between mb-2 text-fontColor3 text-sm'>
                                        <p>Subtotal (3 Items)</p>
                                        <p className='text-base text-primary-light font-medium'>₹ 777</p>
                                    </div>
                                    <div className='flex justify-between mb-2 text-fontColor3 text-sm'>
                                        <p>Shipping Cost</p>
                                        <p className='text-base text-primary-light font-medium'>₹ 20</p>
                                    </div>
                                </div>
                                <div className='w-full h-[1px] bg-gray-300 my-5'></div>
                                <div className='flex justify-between'>
                                    <p className='font-medium text-fontGreen '>Order Total</p>
                                    <p className='font-medium text-fontGreen text-lg'>₹ 797</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CheckOutIndex;
