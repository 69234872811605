import React from 'react'
import loginPic from '../../assets/auth/login.png'
import logo from '../../assets/logo/brand-logo.png'
import { Button, Label, TextInput } from 'flowbite-react'
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa6";
import bg1 from '../../assets/auth/bg1.png'
import bg2 from '../../assets/auth/bg2.png'
import bg3 from '../../assets/auth/bg3.png'

function SignUpIndex() {
    return (
        <>
            <div className='relative'>
                <div className='absolute bottom-0 -z-10'>
                    <img src={bg1} alt="bg1" />
                </div>
                <div className='absolute top-0 right-0'>
                    <img src={bg2} alt="bg2" />
                </div>
                <div className='absolute bottom-0 right-0'>
                    <img src={bg3} alt="bg3" />
                </div>
                <div className='px-[5%] xl:px-[8%] py-32 lg:py-16 h-screen'>
                    <div className='grid grid-cols-12 '>
                        <div className='col-span-12 lg:col-span-6 hidden lg:block'>
                            <img className='h-[480px] xl:h-[550px] 2xl:h-[600px] ' src={loginPic} alt="login form" />
                        </div>
                        <div className='col-span-12 lg:col-span-6 lg:px-20 lg:mt-10'>
                            <div className='flex items-center mb-5'>
                                <img className='-ml-5' src={logo} alt="" />
                                <h3 className='text-xl lg:text-2xl xl:text-4xl font-bold'>Sign Up</h3>
                            </div>
                            <div>
                                <form className="flex max-w-md flex-col gap-4">
                                    <div className='mb-1 xl:mb-3'>
                                        <div className="mb-5 lg:mb-1 xl:mb-3 block">
                                            <Label className='lg:text-sm xl:text-base' htmlFor="name" value="Full Name" />
                                        </div>
                                        <TextInput id="name" type="text" placeholder="Full Name" required />
                                    </div>
                                    <div className='mb-1 xl:mb-3'>
                                        <div className="mb-5 lg:mb-1 xl:mb-3 block">
                                            <Label className='lg:text-sm xl:text-base' htmlFor="email" value="Email or Mobile Number" />
                                        </div>
                                        <TextInput id="email" type="text" placeholder="Email or Mobile Number" required />
                                    </div>
                                    <Button className='bg-background2 rounded-3xl' type="submit">Verify</Button>
                                </form>
                            </div>
                            <div className='mt-8 lg:mt-1 xl:mt-3 2xl:mt-5'>
                                <p className='text-center text-sm xl:text-base'>or continue with</p>
                                <div className='flex justify-around gap-5 my-6 lg:my-5'>
                                    <div className='border-2 lg:px-10 xl:px-14 lg:py-1 xl:py-2 rounded-full'><FcGoogle size={30} /></div>
                                    <div className='border-2 lg:px-10 xl:px-14 lg:py-1 xl:py-2 rounded-full'><FaFacebook size={30} /></div>
                                </div>
                                <p className='text-center text-fontColor3 text-sm xl:text-base'>Already have an account?
                                    <span className='text-base text-primary-light font-semibold ms-3 underline'>Login</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignUpIndex
