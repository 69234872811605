import React from 'react'
import leaf from '../../assets/home/mdi_leaf.png'
import tree from '../../assets/home/mdi_palm-tree.png'
import safety from '../../assets/home/mingcute_safety-certificate-fill.png'
import customer from '../../assets/home/mdi_customer-service.png'
import leaves from '../../assets/home/image 11.png'
import badam from '../../assets/home/image 12.png'

function WhySection() {
    return (
        <>
            <div className='bg-background2'>
                <div className='px-[5%] xl:px-[8%] py-10 lg:py-10 relative'>
                    <h2 className='capitalize font-bold text-white text-xl lg:text-2xl xl:text-3xl'>why us?</h2>
                    <div className='absolute top-0 right-0'>
                        <img src={leaves} alt="leaf" />
                    </div>
                    <div className='absolute bottom-0 left-0'>
                        <img src={badam} alt="leaf" />
                    </div>
                    <div className='grid grid-cols-12 md:gap-10 gap-y-5'>
                        <div className='col-span-12 md:col-span-3 md:mt-10 '>
                            <div className=' border-2 border-white rounded-full w-[100px] h-[100px] mr-auto ml-auto flex items-center justify-center'>
                                <img className='w-[40px] h-[40px]' src={leaf} alt="why leaf" />
                            </div>
                            <div className='mt-8 md:pb-20  '>
                                <p className='text-white text-xs lg:text-sm xl:text-base text-center'>Fresh from the field</p>
                            </div>
                        </div>
                        <div className='col-span-12 md:col-span-3 md:mt-10 '>
                            <div className='border-2 border-white rounded-full w-[100px] h-[100px] mr-auto ml-auto flex items-center justify-center'>
                                <img className='w-[40px] h-[40px]' src={tree} alt="why leaf" />
                            </div>
                            <div className='mt-8 md:pb-20 '>
                                <p className='text-white text-xs lg:text-sm xl:text-base text-center'>10% from each sale donated for trees</p>
                            </div>
                        </div>
                        <div className='col-span-12 md:col-span-3 md:mt-10 '>
                            <div className='border-2 border-white rounded-full w-[100px] h-[100px] mr-auto ml-auto flex items-center justify-center'>
                                <img className='w-[40px] h-[40px]' src={safety} alt="why leaf" />
                            </div>
                            <div className='mt-8 md:pb-20 '>
                                <p className='text-white text-xs lg:text-sm xl:text-base text-center'>International certificate and safe registered</p>
                            </div>
                        </div>
                        <div className='col-span-12 md:col-span-3 md:mt-10 '>
                            <div className='border-2 border-white rounded-full w-[100px] h-[100px] mr-auto ml-auto flex items-center justify-center'>
                                <img className='w-[40px] h-[40px]' src={customer} alt="why leaf" />
                            </div>
                            <div className='mt-8 md:pb-20 pb-20'>
                                <p className='text-white text-xs lg:text-sm xl:text-base text-center'>Greate customer service</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhySection
