import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import NavBar from '../components/navbar/NavBar';
import AboutIndex from '../containers/about/AboutIndex';
import FooterIndex from '../components/footer/FooterIndex';
import MyCart from '../containers/account/myCart/MyCart';
import HomeIndex from '../containers/home/HomeIndex';
import ProductIndex from '../containers/account/viewProduct/ProductIndex';
import ScrollToTop from '../components/scrolltotop/ScrollToTop';
import TeaStoryIndex from '../containers/teaStory/TeaStoryIndex';
import LoginIndex from '../components/auth/LoginIndex';
import SignUpIndex from '../components/auth/SignUpIndex';
import OtpIndex from '../components/auth/OtpIndex';
import CheckOutIndex from '../containers/checkout/CheckOutIndex';


function Layout({ children }) {

    const location = useLocation();
    const hideNavBarAndFooter = location.pathname === '/login' || location.pathname === '/signup' || location.pathname === '/otp';

    return (
        <>
            <ScrollToTop />
            {!hideNavBarAndFooter && <NavBar />}
            {children}
            {!hideNavBarAndFooter && <FooterIndex />}
        </>
    );
}

function RoutesRoot() {
    return (
        <Router>
            <Layout>
                <Routes>
                    <Route path="/" element={<HomeIndex />} />
                    <Route path="/login" element={<LoginIndex />} />
                    <Route path="/signup" element={<SignUpIndex />} />
                    <Route path="/otp" element={<OtpIndex />} />
                    <Route path="/about" element={<AboutIndex />} />
                    <Route path="/my-cart" element={<MyCart />} />
                    <Route path="/product/:name" element={<ProductIndex />} />
                    <Route path="/tea-story" element={<TeaStoryIndex />} />
                    <Route path="/checkout" element={<CheckOutIndex />} />
                    {/* <Route path="*" element={<TeaStoryIndex />} /> */}
                </Routes>
            </Layout>
        </Router>
    );
}

export default RoutesRoot;
