import React from 'react'
import aboutimg from '../../assets/home/about.png'
import leaves from '../../assets/home/leaves.png'
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from 'react-router-dom';


function AboutSection() {
    const navigate = useNavigate();

    const handleLinkClick = (path) => {
        console.log(`Navigating to ${path}`);
        navigate(path);
    };
    return (
        <>
            <div id='about' className='px-[5%] xl:px-[8%] py-10 lg:py-24   bg-background '>
                <h2 className='capitalize text-center font-bold text-primary-light text-xl lg:text-2xl xl:text-3xl mb-5 lg:mb-14 '>about us</h2>
                <div className='grid grid-cols-12 group'>
                    <div className='col-span-12 md:col-span-5 gap-0 z-50 mb-5 md:mb-0 '>
                        <img className='h-[300px] lg:h-[480px] w-full' src={aboutimg} alt="about" />
                    </div>
                    <div className='col-span-12 md:col-span-7 md:flex md:items-center md:-ml-12 lg:-ml-6'>
                        <div className='bg-white shadow-md p-5 md:ps-14 md:pe-20 md:py-5 lg:py-20 rounded-tr-2xl rounded-br-2xl relative border-2 group-hover:border-primary-light group-hover:border-dotted'>
                            <div className='absolute xl:-right-20 xl:-top-20 lg:-right-10 lg:-top-16 -right-5 -top-5 group-hover:scale-125 transition-all duration-500'>
                                <img className='lg:w-[180px] w-[100px]' src={leaves} alt="leaves" />
                            </div>
                            <p className='text-paragraph text-xs lg:text-sm xl:text-base mt-10 md:mt-0 mb-5 '>At Denad, We’re passionate about tea, and it all start with the leaves! We source only the finest organic teas, using traditional methods that care for the environment. This way, every cup tastes amazing and is good for you too.</p>
                            <p className='text-paragraph text-xs lg:text-sm xl:text-base  lg:mb-5'>Our commitment to organic goes beyond just a label. We partner with dedicated tea gardens around the world, ensuring ethical sourcing and sustainable practices from seed to cup</p>
                            <button className='flex items-center capitalize bg-primary-light px-5 p-2 mt-3 text-white rounded-full text-xs lg:text-sm xl:text-base' onClick={() => handleLinkClick('/about')}>read more  <IoIosArrowForward className="ms-1" /></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutSection
