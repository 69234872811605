import React, { useState } from 'react'
import loginPic from '../../assets/auth/login.png'
import logo from '../../assets/logo/brand-logo.png'
import { Button } from 'flowbite-react'
import bg1 from '../../assets/auth/bg1.png'
import bg2 from '../../assets/auth/bg2.png'
import bg3 from '../../assets/auth/bg3.png'
import OTPInput from 'react-otp-input';

function OtpIndex() {
    const [otp, setOtp] = useState();
    return (
        <>
            <div className='relative'>
                <div className='absolute bottom-0 -z-10'>
                    <img src={bg1} alt="bg1" />
                </div>
                <div className='absolute top-0 right-0'>
                    <img src={bg2} alt="bg2" />
                </div>
                <div className='absolute bottom-0 right-0'>
                    <img src={bg3} alt="bg3" />
                </div>
                <div className='px-[5%] xl:px-[8%] py-52 lg:py-16 h-screen'>
                    <div className='grid grid-cols-12 '>
                        <div className='col-span-12 lg:col-span-6 hidden lg:block'>
                            <img className='h-[480px] xl:h-[550px] 2xl:h-[600px] ' src={loginPic} alt="login form" />
                        </div>
                        <div className='col-span-12 lg:col-span-6 lg:px-20 lg:mt-10 lg:py-10'>
                            <div className='flex items-center mb-5'>
                                <img className='-ml-5' src={logo} alt="" />
                                <h3 className='text-xl lg:text-2xl xl:text-4xl font-bold'>OTP</h3>
                            </div>
                            <p className='text-base text-fontColor3 mt-10'>Please enter the OTP (One-Time Password) send to your registered Phone number to complete your verification</p>
                            <div className='mt-10'>
                                <div className='flex '>
                                    <OTPInput
                                        value={otp}
                                        onChange={(e) => setOtp(e)}
                                        inputType="tel"
                                        numInputs={6}
                                        inputStyle="custom-otp-field"
                                        renderInput={(props) => <input {...props} />}
                                    />
                                </div>
                                <p className='mt-5'>Didn't receive an OTP?
                                    <span className='text-primary-light font-medium ms-2'>Resend OTP</span>
                                </p>
                                <div className="flex mt-8">
                                    <Button className='bg-primary-light px-8' size='sm' disabled={!otp || otp.length !== 6}
                                    // onClick={otpVerify}
                                    >
                                        Verify
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OtpIndex
