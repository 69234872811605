import React, { useRef } from 'react';
import teaCup from '../../../assets/home/Group 15.png';
import tealeaf from '../../../assets/home/Group 17.png';
import cookie from '../../../assets/home/Group 19.png';
import coffee from '../../../assets/home/image 84.png';
import strawberry from '../../../assets/home/Group 46.png';
import masalatea from '../../../assets/home/Group 45.png';
import "react-multi-carousel/lib/styles.css";
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-multi-carousel';
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import ProductCard from '../../../components/productCard/ProductCard';

function RelatedProducts() {

    const navigate = useNavigate();
    const carouselRef = useRef(null);

    const items = [
        {
            name: 'tea',
            imgSrc: teaCup,
            alt: 'tea cup',
            description: 'Handpicked from the best tea estates, ensuring each leaf is rich in flavor and antioxidants.',
        },
        {
            name: 'spices',
            imgSrc: tealeaf,
            alt: 'tea leaf',
            description: 'Discover a diverse array of spices, each offering unique flavors and culinary possibilities.',
        },
        {
            name: 'cookies',
            imgSrc: cookie,
            alt: 'cookie',
            description: 'Handpicked from the best tea estates, ensuring each leaf is rich in flavor and antioxidants.',
        },
        {
            name: 'Coffee',
            imgSrc: coffee,
            alt: 'Coffee',
            description: 'Handpicked from the best tea estates, ensuring each leaf is rich in flavor and antioxidants.',
        },
        {
            name: 'Strawberries',
            imgSrc: strawberry,
            alt: 'Strawberries',
            description: 'Handpicked from the best tea estates, ensuring each leaf is rich in flavor and antioxidants.',
        },
        {
            name: 'Masala Tea',
            imgSrc: masalatea,
            alt: 'Masala Tea',
            description: 'Handpicked from the best tea estates, ensuring each leaf is rich in flavor and antioxidants.',
        },
    ];

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 3,

        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    const handleExploreClick = (item) => {
        navigate(`/product/${item.name}`, { state: { product: item } });
    };

    return (
        <>
            <div className='relative'>
                <div>
                    <h2 className='capitalize font-bold text-primary-light text-xl lg:text-2xl xl:text-3xl mb-5 lg:mb-10 text-center'>related products</h2>
                    <div className=' gap-5 gap-y-28 lg:gap-y-40  mb-10'>
                        <Carousel
                            responsive={responsive}
                            ssr={true} // means to render carousel on server-side
                            infinite={true}
                            autoPlay={true}
                            swipeable={true}
                            draggable={true}
                            arrows={false}
                            autoPlaySpeed={2000}
                            keyBoardControl={true}
                            transitionDuration={500}
                            containerClass="carousel-container-productview"
                            removeArrowOnDeviceType={["tablet", "mobile"]}
                            itemClass="carousel-item-padding-40-px"
                            showDots={true}
                            dotListClass="custom-dot-list-productview"
                            ref={carouselRef}
                        >
                            {items.map((item, index) => (
                                <ProductCard
                                    key={index}
                                    item={item}
                                    showExploreButton={true}
                                    onExploreClick={() => handleExploreClick(item)}
                                />
                            ))}
                        </Carousel>
                        <div className='absolute md:bottom-14 left-10 capitalize'>
                            <p className='flex items-center text-xl'><IoMdArrowBack className='me-2' />view all products</p>
                        </div>
                        <div className="absolute bottom-14 right-20 md:right-28 lg:right-10 flex space-x-32 md:space-x-10">
                            <button onClick={() => carouselRef.current.previous()} className="custom-arrow-productview">
                                <IoIosArrowBack />
                            </button>
                            <button onClick={() => carouselRef.current.next()} className="custom-arrow-productview">
                                <IoIosArrowForward />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RelatedProducts;
