import React, { useState, useEffect } from 'react';
import './HeroSection.css'; // Import your CSS file with transitions
import AboutSection from './AboutSection';
import WhySection from './WhySection';
import OurProducts from './OurProducts';
import GreenTea from './GreenTea';
import SpecialOffer from './SpecialOffer';
import FeedBack from './FeedBack';
import ContactUs from './ContactUs';
import BestSeller from './BestSeller';
import Sample2 from './Sample2';
import Sample3 from './Sample3';
import Sample4 from './Sample4';
import Sample5 from './Sample5';
import ScrollToTopButton from '../../components/scrolltotop/ScrollToTopButton';
import FaqSection from './FaqSection';

function HomeIndex() {
    const [currentSample, setCurrentSample] = useState(2); // Start with Sample2
    const [nextSample, setNextSample] = useState(3); // Next sample to be shown
    const [fadeOut, setFadeOut] = useState(false); // State to trigger fade-out effect

    useEffect(() => {
        const id = setInterval(() => {
            setFadeOut(true); // Trigger fade-out effect

            setTimeout(() => {
                setCurrentSample(nextSample); // Change current sample after fade-out
                setFadeOut(false); // Reset fade-out state
            }, 500); // Change component after 500ms (adjust as needed)

            setNextSample((prevSample) => {
                if (prevSample === 2) return 3;
                else if (prevSample === 3) return 4;
                else if (prevSample === 4) return 5;
                else return 2; // loop back to Sample2
            });
        }, 5000); // Change component every 5 seconds (5000 ms)

        return () => {
            clearInterval(id);
        };
    }, [nextSample]);

    const renderSample = () => {
        switch (currentSample) {
            case 2:
                return <Sample2 key="Sample2" className={`sample ${fadeOut ? 'fade-out' : ''}`} />;
            case 3:
                return <Sample3 key="Sample3" className={`sample ${fadeOut ? 'fade-out' : ''}`} />;
            case 4:
                return <Sample4 key="Sample4" className={`sample ${fadeOut ? 'fade-out' : ''}`} />;
            case 5:
                return <Sample5 key="Sample5" className={`sample ${fadeOut ? 'fade-out' : ''}`} />;
            default:
                return null;
        }
    };

    return (
        <>
            <div id='home' className="sample-container">
                {renderSample()}
            </div>
            <OurProducts />
            <GreenTea />
            <BestSeller />
            <SpecialOffer />
            <WhySection />
            <AboutSection />
            <FeedBack />
            <ContactUs />
            <FaqSection />
            <ScrollToTopButton />
        </>
    );
}

export default HomeIndex;
