import React, { useRef, useState } from 'react';
import feedback1 from '../../../assets/home/Ellipse 11.png';
import feedback2 from '../../../assets/home/Ellipse 12.png';
import feedback3 from '../../../assets/home/Ellipse 13.png';
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-multi-carousel';
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import './ProductPage.css';
import AddReview from './AddReview';
import FeedBackCard from '../../../components/feedBackCard/FeedBackCard';

function ProductFeedBack() {

    const carouselRef = useRef(null);
    const [openModal, setOpenModal] = useState(false);

    const toggle = () => {
        setOpenModal(!openModal)
    }

    const items = [
        {
            name: 'Alrin Sherbi',
            imgSrc: feedback1,
            alt: 'tea cup',
            description: '"Emerald Delight Green Tea is my daily go-to! The delicate, smooth flavor is refreshing and invigorating. I feel more energized and focused without the jitters. The health benefits are noticeable, especially for my skin and overall well-being. Highly recommend!"',
        },
        {
            name: 'Robin SD',
            imgSrc: feedback2,
            alt: 'tea leaf',
            description: 'Exceptional quality and taste! Emerald Delight stands out with its pure, natural sweetness and fresh, grassy aroma. It’s my favorite way to start the morning, offering a perfect balance of flavor and health benefits. Truly exceptional!',
        },
        {
            name: 'Shibi TL',
            imgSrc: feedback3,
            alt: 'cookie',
            description: '"A tea lover’s dream! Aromatic, flavorful, and packed with antioxidants. Helps me reduce my caffeine intake from coffee, and I feel much better overall. Beautiful packaging makes it a perfect gift. This tea is truly a delight!"',
        },
    ];

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 3,

        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    return (
        <>
            <div className='relative pt-10'>
                <div className='flex flex-col justify-normal md:flex-row md:justify-between gap-4 items-center mb-10'>
                    <p className='text-nowrap font-medium lg:text-xl'>Reviews <span className='text-fontColor3 text-base'>(9 customer reviews)</span></p>
                    <div className='bg-fontColor3 opacity-20 w-full h-[1px]'></div>
                    <button className='flex items-center text-nowrap border-[1px] border-background2 py-1 px-3 text-sm text-background2 rounded-full'
                        onClick={() => setOpenModal(true)}>Add a Review<IoIosArrowForward className='ms-2' />
                    </button>
                </div>
                <Carousel
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side
                    infinite={true}
                    autoPlay={true}
                    swipeable={true}
                    draggable={true}
                    arrows={false}
                    autoPlaySpeed={2000}
                    keyBoardControl={true}
                    transitionDuration={500}
                    containerClass="carousel-container-productview"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    itemClass="carousel-item-padding-40-px"
                    showDots={true}
                    dotListClass="custom-dot-list-productview"
                    ref={carouselRef}
                >
                    {items.map((item, index) => (
                        <FeedBackCard
                            key={index}
                            item={item}
                        />
                    ))}
                </Carousel>
                <div className="absolute bottom-16 right-12 md:right-28 lg:right-10 flex space-x-32 md:space-x-10">
                    <button onClick={() => carouselRef.current.previous()} className="custom-arrow-productview">
                        <IoIosArrowBack />
                    </button>
                    <button onClick={() => carouselRef.current.next()} className="custom-arrow-productview">
                        <IoIosArrowForward />
                    </button>
                </div>
            </div>
            <AddReview openModal={openModal} setOpenModal={setOpenModal} />
        </>
    );
}

export default ProductFeedBack;
