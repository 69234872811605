import React from 'react';
import { useNavigate } from 'react-router-dom';
import teaCup from '../../assets/home/Group 15.png';
import tealeaf from '../../assets/home/Group 17.png';
import cookie from '../../assets/home/Group 19.png';
import coffee from '../../assets/home/image 84.png';
import strawberry from '../../assets/home/Group 46.png';
import masalatea from '../../assets/home/Group 45.png';
import "./OurProducts.css";
import ProductCard from '../../components/productCard/ProductCard';

function OurProducts() {
    const navigate = useNavigate();

    const items = [
        {
            name: 'tea',
            imgSrc: teaCup,
            alt: 'tea cup',
            description: 'Handpicked from the best tea estates, ensuring each leaf is rich in flavor and antioxidants.',
        },
        {
            name: 'spices',
            imgSrc: tealeaf,
            alt: 'tea leaf',
            description: 'Discover a diverse array of spices, each offering unique flavors and culinary possibilities.',
        },
        {
            name: 'cookies',
            imgSrc: cookie,
            alt: 'cookie',
            description: 'Handpicked from the best tea estates, ensuring each leaf is rich in flavor and antioxidants.',
        },
        {
            name: 'Coffee',
            imgSrc: coffee,
            alt: 'Coffee',
            description: 'Handpicked from the best tea estates, ensuring each leaf is rich in flavor and antioxidants.',
        },
        {
            name: 'Strawberries',
            imgSrc: strawberry,
            alt: 'Strawberries',
            description: 'Handpicked from the best tea estates, ensuring each leaf is rich in flavor and antioxidants.',
        },
        {
            name: 'Masala Tea',
            imgSrc: masalatea,
            alt: 'Masala Tea',
            description: 'Handpicked from the best tea estates, ensuring each leaf is rich in flavor and antioxidants.',
        },
    ];

    const handleExploreClick = (item) => {
        navigate(`/product/${item.name}`, { state: { product: item } });
    };

    return (
        <div id='products' className='px-[5%] xl:px-[8%] py-5 md:py-10'>
            <div>
                <h2 className='capitalize font-bold text-primary-light text-xl lg:text-2xl xl:text-3xl mb-5 text-center'>Our Products</h2>
                <p className='text-fontColor text-center text-xs xl:text-base mb-32'>Explore the exquisite flavors and aromas of our premium product range. Whether you're seeking the perfect cup of tea or coffee, the rich taste of exotic spices, or the natural sweetness of fresh strawberries, we offer the finest selection to enhance your daily moments.</p>
                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 gap-y-40 mt-10'>
                    {items.map((item, index) => (
                        <ProductCard
                            key={index}
                            item={item}
                            showExploreButton={true}
                            onExploreClick={() => handleExploreClick(item)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default OurProducts;
