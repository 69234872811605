import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS for default styles
import React, { useEffect, useMemo, useState } from 'react';
import eclipse3 from '../../assets/hero-section/Ellipse 8808.png';
import eclipse4 from '../../assets/hero-section/Ellipse 8807.png';
import cookie from '../../assets/hero-section/image 54.png';
import cookie2 from '../../assets/hero-section/image 57.png';
import seed1 from '../../assets/hero-section/image 70.png';
import seed2 from '../../assets/hero-section/image 73.png';
import seed3 from '../../assets/hero-section/image 74.png';
import cookiebottom from '../../assets/hero-section/Ellipse 8805.png';
import cookietop from '../../assets/hero-section/Ellipse 8804.png';

function Sample3() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [data, setData] = useState({
        heading: '',
        paragraph: '',
        toprightimg: '',
        bottomleftimg: '',
        eclipse3: '',
        eclipse4: '',
        rightcherry1: '',
        rightcherry2: '',
        leaf1: '',
        leaf2: '',
        leaf3: '',
    });

    const items = useMemo(() => [
        {
            heading: 'Indulge in Homemade Cookies',
            paragraph: 'Each tea purchased comes with organically and ethically groewn loose leaf and handpicked flowers carefully blended to create the perfect cup of tea everyday',
            toprightimg: cookietop,
            bottomleftimg: cookiebottom,
            eclipse3: eclipse3,
            eclipse4: eclipse4,
            rightcherry1: cookie2,
            rightcherry2: cookie,
            leaf1: seed1,
            leaf2: seed2,
            leaf3: seed3,
        },
    ], []);


    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: true,
        });
    }, []);

    useEffect(() => {
        setData(items[currentIndex]);
        setTimeout(() => {
            AOS.refresh();
        }, 0);
    }, [currentIndex, items]);

    return (
        <>

            <div className='relative lg:pt-32 h-screen'>
                <div className='-z-10 absolute top-0 right-0'>
                    <img className='w-[250px] md:w-[600px] lg:w-[630px] xl:w-[800px]' src={data.toprightimg} alt="eclipse" />
                </div>
                <div className='absolute bottom-0 left-0'>
                    <img className='w-[300px] md:w-[800px] lg:w-[450px] xl:w-[600px] h-[200px]' src={data.bottomleftimg} alt="eclipse 2" />
                </div>
                <div id="hero" className='z-40 px-[5%] xl:px-[8%] py-14 lg:py-14 xl:pb-16 relative '>
                    <div className='grid grid-cols-12 gap-5  relative '>
                        <div className='col-span-12 lg:col-span-5 relative'>
                            <div className='xl:mt-10'>
                                <div className='absolute -z-10 hidden lg:block bottom-20 -left-10'>
                                    <img className='w-[350px]' src={data.eclipse3} alt="eclipse 3" />
                                </div>
                                <div className='absolute -z-10 hidden lg:block -top-32 right-10 '>
                                    <img className='w-[350px]' src={data.eclipse4} alt="eclipse 4" />
                                </div>
                                <h1 className='text-3xl md:text-4xl  lg:text-3xl xl:text-4xl md:w-[55%] lg:w-[80%] font-bold text-primary-light lg:leading-[30px] xl:leading-[40px] 2xl:leading-[50px]'>{data.heading}</h1>
                                <p className='text-base lg:text-sm mt-3 xl:text-base md:w-[70%] lg:w-[80%] text-fontColor mb-3 lg:leading-[25px] xl:leading-[25px] 2xl:leading-[35px]'>{data.paragraph}</p>
                                <div className='mt-10 lg:mt-2 xl:mt-8' >
                                    <button className='capitalize bg-primary-light text-white text-sm lg:text-base rounded-full px-7 p-2'>Explore now</button>
                                </div>
                            </div>
                            <div className='relative '>
                                <div className='absolute -top-32 right-0'>
                                    <img data-aos="zoom-in" data-aos-duration="2000" className='w-[50px] md:w-[100px] lg:w-[70px]' src={data.leaf1} alt="leaf1" />
                                </div>
                                <div className='absolute hidden lg:block lg:top-5 xl:-top-5 2xl:top-0 lg:-right-14 xl:right-8'>
                                    <img data-aos="fade-right" data-aos-duration="2000" className='lg:h-[150px] xl:h-[200px]  w-[300px] h-[100px] lg:w-[250px] xl:w-[250px] 2xl:w-[300px]' src={data.rightcherry1} alt="cherry1" />
                                </div>
                            </div>
                        </div>
                        <div className='col-span-12 lg:col-span-7 relative'>
                            <div className='flex justify-center'>
                                <div className='relative'>
                                    <div className='absolute top-0 right-0 hidden lg:block'>
                                        <img data-aos="zoom-in" data-aos-duration="2000" className='lg:w-[50px] xl:w-[70px]' src={data.leaf2} alt="leaf2" />
                                    </div>
                                    <div className='relative'>
                                        <img data-aos="fade-left" data-aos-duration="2000" className='hidden lg:block w-[300px] lg:w-[350px] xl:w-[400px] h-[300px] lg:h-[350px] xl:h-[450px]' src={data.rightcherry2} alt="cherry" />
                                        <img className='block lg:hidden w-[400px] md:w-[500px] h-[400px] md:h-[500px] ' src={data.rightcherry2} alt="cherry" />
                                    </div>
                                    <div className='absolute lg:-bottom-7 lg:left-24 '>
                                        <div className='flex justify-start'>
                                            <img data-aos="zoom-in" data-aos-duration="2000" className='w-[50px] md:w-[100px] lg:w-[70px]' src={data.leaf3} alt="leaf3" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Sample3;
