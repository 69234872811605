import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import contact from '../../assets/home/Rectangle 25.png'
import ContactDetails from '../../components/contact/ContactDetails';

const schema = yup.object().shape({
    name: yup.string().required('Name is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    message: yup.string().required('Message is required'),
});


function ContactUs() {

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const formRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (formRef.current && !formRef.current.contains(event.target)) {
                reset(); // Reset the form when clicking outside
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [reset]);


    const onSubmit = async (data) => {
        try {
            // Submit data to API
            const response = await fetch('YOUR_API_ENDPOINT', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                // Reset form on successful submission
                reset();
                alert('Form submitted successfully!');
            } else {
                throw new Error('Failed to submit form');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Failed to submit form');
        }
    };

    return (
        <>
            <div id='contact' className='px-[5%] xl:px-[8%] py-10 lg:py-16 bg-background4 relative pb-20'>
                <h2 className='capitalize font-bold text-center text-primary-light text-xl lg:text-2xl xl:text-3xl mb-5 lg:mb-4 '>contact us</h2>
                <p className='text-paragraph text-center text-xs lg:text-sm xl:text-base mb-5 lg:mb-14'>We believe in the power of connection and value your feedback, questions, and ideas. Let’s build a lasting connection together and let’s stay connected.</p>
                <div className='grid grid-cols-12 lg:gap-5 bg-background5 p-3 lg:p-8 rounded-3xl border-2 border-solid mb-0 lg:mb-8 '>
                    <div className='col-span-12 lg:col-span-6 mt-5 lg:mt-0'>
                        <div className='flex justify-center lg:justify-start'>
                            <img className='' src={contact} alt="contact" />
                        </div>
                        <p className='text-sm xl:text-base mt-3 text-fontColor3'>Connect with us to become a Partner/Distributor/Retailer.</p>
                    </div>
                    <div ref={formRef} className='col-span-12 lg:col-span-6 flex items-center mt-5 lg:mt-0'>
                        <form onSubmit={handleSubmit(onSubmit)} className='rounded-xl'>
                            <label className='text-para lg:text-sm xl:text-base text-center' name='name'>Full Name</label>
                            <input
                                type="text"
                                {...register('name')}
                                className={`input-style border-[1.5px] border-primary-light w-full mt-2  p-2 rounded mb-5 lg:text-sm xl:text-base
                                    ${errors.name ? 'border-red-500' : 'border-gray-300'}`}
                                placeholder="Enter Your Full Name"
                            />

                            <label className='text-para lg:text-sm xl:text-base' name='name'>Email</label>
                            <input
                                type="text"
                                {...register('email')}
                                className={`input-style border-[1.5px] border-primary-light w-full mt-2  p-2 rounded mb-5 lg:text-sm xl:text-base
                                    ${errors.email ? 'border-red-500' : 'border-gray-300'}`}
                                placeholder="Enter Your Email"
                            />

                            <label className='text-para lg:text-sm xl:text-base' name='name'>Message</label>
                            <textarea
                                {...register('message')}
                                className={`input-style border-[1.5px] border-primary-light w-full mt-2  p-2 rounded mb-5 lg:text-sm xl:text-base
                                    ${errors.message ? 'border-red-500' : 'border-gray-300'}`}
                                rows="4"
                            // placeholder=""
                            />
                            <div className='flex justify-center'>
                                <button
                                    type="submit"
                                    className="button-style capitalize bg-gradient-to-r from-green to-primary px-24 bg-primary-light text-white py-2 rounded-xl"
                                >
                                    send message
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <ContactDetails />
            </div>
        </>
    )
}

export default ContactUs
