import { Button, Label, Modal, TextInput, Textarea } from 'flowbite-react'
import React from 'react'

function AddReview({ openModal, setOpenModal }) {
    function onCloseModal() {
        setOpenModal(false);

    }
    return (
        <>
            <Modal show={openModal} onClose={onCloseModal} popup>
                <Modal.Header />
                <Modal.Body>
                    <div className='p-5 bg-background3 rounded-3xl'>
                        <h6 className='text-xl font-medium mb-3'>Add a Reviews</h6>
                        <p className='text-fontColor3 text-base mb-5'>Rate this Product?</p>
                        <form className=" ">
                            <div className="">
                                <div className="mb-2 block">
                                    <Label htmlFor="comment" value="Write a comment" />
                                </div>
                                <Textarea className='w-full p-2' id="comment" placeholder="Write a comment..." required rows={4} />
                            </div>
                            <div className='grid grid-cols-12 gap-5 mt-4'>
                                <div className='col-span-12 lg:col-span-6'>
                                    <div className="mb-2 block">
                                        <Label htmlFor="name" value="Your name" />
                                    </div>
                                    <TextInput id="name" type="text" placeholder="Enter Your Full Name" required shadow />
                                </div>
                                <div className='col-span-12 lg:col-span-6'>
                                    <div className="mb-2 block">
                                        <Label htmlFor="email2" value="Your email" />
                                    </div>
                                    <TextInput id="email2" type="email" placeholder="Enter Your Email Address" required shadow />
                                </div>

                            </div>
                            <div className="w-full mt-5 flex justify-end">
                                <Button className='custom-btn px-3'>Submit a Review</Button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddReview
