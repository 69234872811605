import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import ProductFeedBack from './ProductFeedback';
import RelatedProducts from './RelatedProducts';
import star from '../../../assets/home/Star 1.png';
import { IoIosArrowForward } from "react-icons/io";
import ScrollToTopButton from '../../../components/scrolltotop/ScrollToTopButton';

function ProductIndex() {

    const location = useLocation();
    const { product } = location.state || {};
    const [quantity, setQuantity] = useState(1);

    const decrementQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    const incrementQuantity = () => {
        setQuantity(quantity + 1);
    };

    return (
        <div className='relative'>
            <div className='bg-productBanner bg-no-repeat w-full bg-cover h-52 lg:h-[65vh] xl:h-[80vh] absolute -z-10'> </div>
            <div className='px-[5%] xl:px-[8%] py-10 pt-[150px] lg:pt-[400px]'>
                <div className='bg-background3 p-8 rounded-3xl mb-10'>
                    {product ? (
                        <>
                            <h2 className='capitalize text-xl lg:text-3xl font-bold text-center text-primary-light mb-5'>explore product</h2>
                            <p className='text-fontColor3 text-center text-xs md:text-sm'>{product.description}</p>
                            <div>
                                <div className='grid grid-cols-12 gap-5 bg-white my-5 rounded-3xl'>
                                    <div className='col-span-12 md:col-span-5 mx-auto lg:mx-auto flex items-center'>
                                        <img src={product.imgSrc} alt={product.alt} />
                                    </div>
                                    <div className='col-span-12 md:col-span-7 p-3 md:p-8 lg:mt-10'>
                                        <h3 className='text-2xl capitalize font-medium mb-4'>{product.name}</h3>
                                        <div className='md:flex md:gap-3 '>
                                            <p className="flex mb-5">
                                                <img className="ms-1 w-[15px] h-[15px] md:w-[20px] md:h-[20px]" src={star} alt="star" />
                                                <img className="ms-1 w-[15px] h-[15px] md:w-[20px] md:h-[20px]" src={star} alt="star" />
                                                <img className="ms-1 w-[15px] h-[15px] md:w-[20px] md:h-[20px]" src={star} alt="star" />
                                                <img className="ms-1 w-[15px] h-[15px] md:w-[20px] md:h-[20px]" src={star} alt="star" />
                                                <img className="ms-1 w-[15px] h-[15px] md:w-[20px] md:h-[20px]" src={star} alt="star" />
                                            </p>
                                            <p >(9 customer reviews)</p>
                                        </div>
                                        <p className='mt-3 mb-2 lg:mb-5 text-fontColor3 text-xs lg:text-sm xl:text-base '>Green tea, revered for centuries, is more than just a drink—it's a ritual, a source of well-being, and a journey into a world of exquisite flavors. At Denad, we bring you the finest green teas, each carefully selected to provide a refreshing and invigorating experience</p>
                                        <div className='flex gap-2 lg:gap-4 mb-3 lg:mb-5 text-sm md:text-base'>
                                            <p>Availablity:</p>
                                            <p>In Stock</p>
                                        </div>
                                        <div className='flex justify-between lg:justify-normal  lg:gap-16 capitalize'>
                                            <div>
                                                <p className='lg:mb-5 text-sm md:text-base'>price</p>
                                                <p className='text-primary-light font-medium text-sm md:text-base'><span className='line-through text-fontColor3 me-2 '>₹350</span> ₹ 259</p>
                                            </div>
                                            <div>
                                                <p className='lg:mb-5 text-sm md:text-base'>Quantity</p>
                                                <div className='border-[1px] md:border-2 flex justify-center items-center rounded-full  border-primary-light'>
                                                    <button onClick={decrementQuantity} className='text-primary-light text-sm md:text-base px-3 py-1 rounded-l-full'>-</button>
                                                    <p className='text-primary-light text-sm md:text-base px-3 py-1'>{quantity}</p>
                                                    <button onClick={incrementQuantity} className='text-primary-light text-sm md:text-base px-3 py-1 rounded-r-full'>+</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex justify-between lg:justify-normal lg:gap-10 mt-5 '>
                                            <button className='border-2 text-primary-light rounded-full px-4 capitalize border-primary-light p-1 text-xs lg:text-sm'>add to cart</button>
                                            <button className='flex items-center capitalize bg-primary-light text-white px-4 p-1  rounded-full text-xs lg:text-sm'>order now <IoIosArrowForward className='ms-2' /> </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='text-justify'>
                                    <h6 className='font-medium text-xl mb-3'>Overview</h6>
                                    <p className='text-fontColor3 text-xs lg:text-sm xl:text-base'>Green tea is the second healthiest beverage in the world after water. It is packed with high antioxidants and nutrients properties that have positive effects on the body. If you are looking for green tea for weight loss then it’s time to switch your cup of tea with organic green tea.</p>
                                    <p className='text-fontColor3 text-xs lg:text-sm xl:text-base'>Green tea has gained massive fame in India and the United States for its maximum health benefits. It is less processed tea, thus it has strong antioxidants and polyphenols. This healthy tea has less caffeine which aids digestion, control blood sugar levels, mental health, type 2 diabetes, and many more. It is evidence-based green tea organic with no chemicals that better your standard of living. Green tea also helps in preventing the risk of cancer such as lung, skin, stomach, bladder, ovarian, and breast. The number of researchers believes drinking green tea is the safest beverage for anyone.</p>
                                </div>
                            </div>
                            <ProductFeedBack />
                        </>
                    ) : (
                        <p>Product not found.</p>
                    )}
                </div>
                <RelatedProducts />
            </div>
            <ScrollToTopButton />
        </div>
    );
}

export default ProductIndex;
