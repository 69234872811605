import { Button, Checkbox, Label, TextInput } from 'flowbite-react';
import React, { useEffect } from 'react';

const AddressForm = ({ isOpen, onClose, children }) => {

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" onClick={onClose}>
            <div className="rounded-lg shadow-lg w-11/12 md:w-1/3" onClick={(e) => e.stopPropagation()}>
                <div className="relative p-2">
                    <button
                        className="absolute top-1 -right-3 bg-white px-2 shadow-md rounded-sm text-gray-700 hover:text-gray-900"
                        onClick={onClose}
                    >
                        &times;
                    </button>
                </div>
                <div className=" bg-white rounded-b-[40px] rounded-tl-[40px] px-5 p-4">
                    <h2>Add a new Address</h2>
                    <div className='w-full h-[1px] bg-gray-300 my-2'></div>
                    <form className="flex  flex-col gap-2">
                        <div className='flex justify-between'>
                            <div>
                                <div className="mb-2 block">
                                    <Label className='text-sm' htmlFor="fname" value="First Name" />
                                </div>
                                <TextInput className='w-full rounded-[0px]' id="fname" type="text" placeholder="First Name" required />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label className='text-sm' htmlFor="lname" value="Last Name" />
                                </div>
                                <TextInput className='w-full rounded-[0px]' id="lname" type="text" placeholder="Last Name" required />
                            </div>
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label className='text-sm' htmlFor="address" value="Street Address" />
                            </div>
                            <TextInput className='w-full rounded-[0px]' id="address" type="text" placeholder="Street Address" required />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label className='text-sm' htmlFor="city" value="Town/City" />
                            </div>
                            <TextInput className='w-full rounded-[0px]' id="city" type="text" placeholder="Town/City" required />
                        </div>
                        <div className='flex justify-between'>
                            <div>
                                <div className="mb-2 block">
                                    <Label className='text-sm' htmlFor="country" value="Country" />
                                </div>
                                <TextInput className='w-full rounded-[0px]' id="country" type="text" placeholder="Country" required />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label className='text-sm' htmlFor="zip" value="Postcode/Zip Code" />
                                </div>
                                <TextInput className='w-full rounded-[0px]' id="zip" type="text" placeholder="Postcode/Zip Code" required />
                            </div>
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label className='text-sm' htmlFor="mobile" value="Phone Number" />
                            </div>
                            <TextInput className='w-full rounded-[0px]' id="mobile" type="email" placeholder="Phone Number" required />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label className='text-sm' htmlFor="email1" value="Email Address" />
                            </div>
                            <TextInput className='w-full rounded-[0px]' id="email1" type="email" placeholder="Email Address" required />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label className='text-sm' htmlFor="apartment" value="Apartment/House number(optional)" />
                            </div>
                            <TextInput className='w-full rounded-[0px]' id="apartment" type="text" required />
                        </div>
                        <div className='flex justify-end'>
                            <Button className='bg-primary-light rounded-full capitalize' type="submit">add  address</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddressForm;
