import React, { useRef } from 'react'
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-multi-carousel';
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import tea from '../../assets/home/image 37.png'
import masalatea from '../../assets/home/Group 45.png'
import coffee from '../../assets/home/Group 23.png'
import leaficon from '../../assets/home/Vector.png'
import cup from '../../assets/home/Group (1).png'
import frame from '../../assets/home/Frame.png'
import heart from '../../assets/home/Group (2).png'
import breath from '../../assets/home/Frame (1).png'
import cancer from '../../assets/home/Capa_1.png'
import './ProductBenefits.css'

function GreenTea() {
    const carouselRefDesktop = useRef(null);
    const carouselRefMobile = useRef(null);

    const data = [
        {
            title: "Benifits of Green Tea",
            imgUrl: tea,
            features: [
                {
                    title: "Rich in Antioxidants",
                    iconUrl: leaficon,
                    content: "These antioxidants reduce inflammation and protect against various diseases.",
                },
                {
                    title: " Enhances Brain Function",
                    iconUrl: cup,
                    content: "Caffeine: While lower in caffeine than coffee, green tea provides a milder, more stable energy boost without the jitteriness.",
                },
                {
                    title: "Enhances Digestive Health",
                    iconUrl: frame,
                    content: "Green tea can promote healthy digestion by stimulating the production of digestive enzymes and promoting a healthy gut flora balance.",
                },
                {
                    title: "Supports Heart Health",
                    iconUrl: heart,
                    content: "Green tea can lower total and LDL cholesterol, reducing the risk of cardiovascular disease.",
                },
                {
                    title: " Reduces Bad Breath",
                    iconUrl: breath,
                    content: "The antibacterial properties help in maintaining oral hygiene, reducing bad breath.",
                },
                {
                    title: "Prebvent Cancer",
                    iconUrl: cancer,
                    content: "the antioxidants in green tea may lower the risk of certain types of cancer by protecting cells from DNA damage and inhibiting the growth of cancer cells.",
                },
            ]
        },
        {
            title: "Benifits of Masala Tea",
            imgUrl: masalatea,
            features: [
                {
                    title: "Rich in Antioxidants",
                    iconUrl: leaficon,
                    content: "These antioxidants reduce inflammation and protect against various diseases.",
                },
                {
                    title: " Enhances Brain Function",
                    iconUrl: cup,
                    content: "Caffeine: While lower in caffeine than coffee, green tea provides a milder, more stable energy boost without the jitteriness.",
                },
                {
                    title: "Enhances Digestive Health",
                    iconUrl: frame,
                    content: "Green tea can promote healthy digestion by stimulating the production of digestive enzymes and promoting a healthy gut flora balance.",
                },
                {
                    title: "Supports Heart Health",
                    iconUrl: heart,
                    content: "Green tea can lower total and LDL cholesterol, reducing the risk of cardiovascular disease.",
                },
                {
                    title: " Reduces Bad Breath",
                    iconUrl: breath,
                    content: "The antibacterial properties help in maintaining oral hygiene, reducing bad breath.",
                },
                {
                    title: "Prebvent Cancer",
                    iconUrl: cancer,
                    content: "the antioxidants in green tea may lower the risk of certain types of cancer by protecting cells from DNA damage and inhibiting the growth of cancer cells.",
                },
            ]
        },
        {
            title: "Benifits of Coffee",
            imgUrl: coffee,
            features: [
                {
                    title: "Rich in Antioxidants",
                    iconUrl: leaficon,
                    content: "These antioxidants reduce inflammation and protect against various diseases.",
                },
                {
                    title: " Enhances Brain Function",
                    iconUrl: cup,
                    content: "Caffeine: While lower in caffeine than coffee, green tea provides a milder, more stable energy boost without the jitteriness.",
                },
                {
                    title: "Enhances Digestive Health",
                    iconUrl: frame,
                    content: "Green tea can promote healthy digestion by stimulating the production of digestive enzymes and promoting a healthy gut flora balance.",
                },
                {
                    title: "Supports Heart Health",
                    iconUrl: heart,
                    content: "Green tea can lower total and LDL cholesterol, reducing the risk of cardiovascular disease.",
                },
                {
                    title: " Reduces Bad Breath",
                    iconUrl: breath,
                    content: "The antibacterial properties help in maintaining oral hygiene, reducing bad breath.",
                },
                {
                    title: "Prebvent Cancer",
                    iconUrl: cancer,
                    content: "the antioxidants in green tea may lower the risk of certain types of cancer by protecting cells from DNA damage and inhibiting the growth of cancer cells.",
                },
            ]
        },

    ]

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <>
            <div className='px-[5%] xl:px-[8%] py-10 bg-background6 relative lg:block hidden'>
                <Carousel
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side
                    infinite={true}
                    autoPlay={true}
                    swipeable={true}
                    draggable={true}
                    arrows={false}
                    autoPlaySpeed={2000}
                    keyBoardControl={true}
                    transitionDuration={500}
                    containerClass="carousel-container-product"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    itemClass="carousel-item-padding-40-px"
                    showDots={true}
                    dotListClass="custom-dot-list-product"
                    ref={carouselRefDesktop}
                >
                    {data.map((item, index) => (
                        <div key={index} >
                            <h2 className='capitalize text-center font-bold text-primary-light text-xl lg:text-2xl xl:text-3xl mb-5 lg:mb-14 '>{item.title}</h2>
                            <div className='flex gap-8'>
                                <div className='grid order-3 lg:order-1'>
                                    {item.features.slice(0, 3).map((eachFeature, j) => (
                                        <div key={j} className='col-span-12 lg:flex lg:flex-col lg:items-end mb-10'>
                                            <div className='flex items-center '>
                                                <h6 className='font-semibold order-2 lg:order-1 text-black'>{eachFeature.title} </h6>
                                                <img className='me-2 lg:me-0 lg:ms-2 order-1 lg:order-2' src={eachFeature.iconUrl} alt="" />
                                            </div>
                                            <p className='mt-2 lg:text-end text-paragraph text-[12px] lg:text-xs xl:text-sm '>{eachFeature.content}</p>
                                        </div>
                                    ))}
                                </div>
                                <div className='order-1 lg:order-2 flex justify-center hover:scale-110 transition-all duration-500 lg:flex-none lg:justify-center lg:items-center'>
                                    <img className='w-[400px] h-[300px]' src={item.imgUrl} alt="green tea" />
                                </div>
                                <div className='order-2 lg:order-3'>
                                    <div className='col-span-4 grid'>
                                        {item.features.slice(3, 6).map((eachFeature, j) => (
                                            <div key={j} className='col-span-12  mb-10'>
                                                <div className='flex items-center '>
                                                    <img className='me-2' src={eachFeature.iconUrl} alt="" />
                                                    <h6 className='font-semibold'>{eachFeature.title} </h6>
                                                </div>
                                                <p className='mt-2  text-paragraph text-[12px] lg:text-xs xl:text-sm '>{eachFeature.content}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Carousel>
                <div className="absolute bottom-16 right-28 lg:right-40 flex space-x-24 md:space-x-10">
                    <button onClick={() => carouselRefDesktop.current.previous()} className="custom-arrow-product">
                        <IoIosArrowBack />
                    </button>
                    <button onClick={() => carouselRefDesktop.current.next()} className="custom-arrow-product">
                        <IoIosArrowForward />
                    </button>
                </div>
            </div>

            <div className='px-[5%] xl:px-[8%] py-10 bg-background6 relative block lg:hidden'>
                <Carousel
                    responsive={responsive}
                    ssr={true}
                    infinite={true}
                    autoPlay={true}
                    swipeable={true}
                    draggable={true}
                    arrows={false}
                    autoPlaySpeed={2000}
                    keyBoardControl={true}
                    transitionDuration={500}
                    containerClass="carousel-container-product-mobile"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    itemClass="carousel-item-padding-40-px"
                    showDots={true}
                    dotListClass="custom-dot-list-product"
                    ref={carouselRefMobile}
                >
                    {data.map((item, index) => (
                        <div key={index} >
                            <h2 className='capitalize text-center font-bold text-primary-light text-xl lg:text-2xl xl:text-3xl mb-5 '>{item.title}</h2>
                            <div className='grid grid-cols-12'>
                                <div className='col-span-12 grid order-3 lg:order-1'>
                                    {item.features.slice(0, 3).map((eachFeature, j) => (
                                        <div key={j} className='col-span-12 lg:flex lg:flex-col lg:items-end mb-5'>
                                            <div className='flex items-center '>
                                                <h6 className='font-semibold order-2 lg:order-1 text-black'>{eachFeature.title} </h6>
                                                <img className='me-2 lg:me-0 lg:ms-2 order-1 lg:order-2' src={eachFeature.iconUrl} alt="" />
                                            </div>
                                            <p className='mt-2 lg:text-end text-paragraph text-[12px] lg:text-xs xl:text-sm '>{eachFeature.content}</p>
                                        </div>
                                    ))}
                                </div>
                                <div className='col-span-12 order-1 lg:order-2 flex justify-center hover:scale-110 transition-all duration-500 lg:flex-none lg:justify-center lg:items-center'>
                                    <img className='w-[300px] h-[200px]' src={item.imgUrl} alt="green tea" />
                                </div>
                                <div className='col-span-12 order-2 lg:order-3'>
                                    <div className='col-span-4 grid'>
                                        {item.features.slice(3, 6).map((eachFeature, j) => (
                                            <div key={j} className='col-span-12  mb-5'>
                                                <div className='flex items-center '>
                                                    <img className='me-2' src={eachFeature.iconUrl} alt="" />
                                                    <h6 className='font-semibold'>{eachFeature.title} </h6>
                                                </div>
                                                <p className='mt-2  text-paragraph text-[12px] lg:text-xs xl:text-sm '>{eachFeature.content}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Carousel>
                <div className="absolute bottom-14 right-28 lg:right-40 flex space-x-24 md:space-x-10">
                    <button onClick={() => carouselRefMobile.current.previous()} className="custom-arrow-product">
                        <IoIosArrowBack />
                    </button>
                    <button onClick={() => carouselRefMobile.current.next()} className="custom-arrow-product">
                        <IoIosArrowForward />
                    </button>
                </div>
            </div>
        </>
    )
}

export default GreenTea
