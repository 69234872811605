import React from 'react'
import one from '../../assets/tea-story/01.png'
import two from '../../assets/tea-story/02.png'

function GradesOfTea() {

    const data = [
        {
            title: "Whole Leaf",
            titleImg: one,
            description: "Whole leaf tea is made from the largest, highest-quality tea leaves. It is generally considered to be the best grade of tea because it has the most complex flavor profile and the highest levels of antioxidants. Whole leaf tea is typically harvested by hand and undergoes minimal processing to preserve its natural flavor and aroma.",
            feature: [
                {
                    grade: "OP",
                    description: "Orange Pekoe:  Main grade, consisting of long wiry leaves without tips.",
                },
                {
                    grade: "OP1",
                    description: "More delicate than OP; long, wiry leaf with a light liquor.",
                },
                {
                    grade: "OPA",
                    description: "Bolder than OP; long leaf tea which ranges from tightly wound to almost open.",
                },
                {
                    grade: "OPS",
                    description: "Orange Pekoe Superior:  Primarily from Indonesia; similar to OP.",
                },
                {
                    grade: "FOP",
                    description: "Flowery Orange Pekoe: High-quality tea with a long leaf and few tips, considered the second grade in Assam, Dooars, and Bangladesh teas, but the first grade in China.",
                },
                {
                    grade: "FOP1",
                    description: "Limited to only the highest quality leaves in the FOP classification.",
                },
                {
                    grade: "GFOP",
                    description: "Golden Flowery Orange Pekoe: Higher proportion of tip than FOP. Top grade in the Milima and Marinyn regions, but uncommon in Assam and Darjeeling.",
                },
                {
                    grade: "TGFOP",
                    description: "Tippy Golden Flowery Orange Pekoe: The highest proportion of tip, and the main grade in Nepal , Darjeeling and Assam.",
                },
                {
                    grade: "TGFOP1",
                    description: "Limited to only the highest quality leaves in the TGFOP classification.",
                },
                {
                    grade: "FTGFOP",
                    description: "Finest Tippy Golden Flowery Orange Pekoe: Highest quality grade.",
                },
                {
                    grade: "FTGFOP1 ,STGFOP, SFTGFOP",
                    description: "Special Finest Tippy Golden Flowery Orange Pekoe Limited to only the highest quality leaves in the FTGFOP classification.",
                },
            ]
        },
        {
            title: "Broken Leaf",
            titleImg: two,
            description: "Broken leaf tea is made from larger tea leaves that have been broken into smaller pieces. This grade of tea is often used in tea bags because it infuses quickly and easily. Broken leaf tea has a more robust flavor than whole leaf tea, but it may not have the same complexity of flavor.",
            feature: [
                {
                    grade: "BT",
                    description: "Orange Pekoe: Main grade, consisting of long wiry leaves without tips.",
                },
                {
                    grade: "OP1",
                    description: "More delicate than OP; long, wiry leaf with a light liquor.",
                },
                {
                    grade: "OPA",
                    description: "Bolder than OP; long leaf tea which ranges from tightly wound to almost open.",
                },
                {
                    grade: "OPS",
                    description: "Orange Pekoe Superior:  Primarily from Indonesia; similar to OP.",
                },
                {
                    grade: "FOP",
                    description: "Flowery Orange Pekoe: High-quality tea with a long leaf and few tips, considered the second grade in Assam, Dooars, and Bangladesh teas, but the first grade in China.",
                },
                {
                    grade: "FOP1",
                    description: "Limited to only the highest quality leaves in the FOP classification.",
                },
                {
                    grade: "GFOP",
                    description: "Golden Flowery Orange Pekoe: Higher proportion of tip than FOP. Top grade in the Milima and Marinyn regions, but uncommon in Assam and Darjeeling.",
                },
                {
                    grade: "TGFOP",
                    description: "Tippy Golden Flowery Orange Pekoe: The highest proportion of tip, and the main grade in Nepal , Darjeeling and Assam.",
                },
                {
                    grade: "TGFOP1",
                    description: "Limited to only the highest quality leaves in the TGFOP classification.",
                },
                {
                    grade: "FTGFOP",
                    description: "Finest Tippy Golden Flowery Orange Pekoe: Highest quality grade.",
                },
                {
                    grade: "FTGFOP1 ,STGFOP, SFTGFOP",
                    description: "Special Finest Tippy Golden Flowery Orange Pekoe Limited to only the highest quality leaves in the FTGFOP classification.",
                },
            ]
        }
    ]



    return (
        <>
            <div className=' p-5 lg:px-10 pt-5 lg:pb-10 py-5 bg-background6'>
                <div className='flex gap-3'>
                    <div className='bg-fontColor3 opacity-20 w-full h-[1px] my-5 lg:my-5'></div>
                    <h2 className='text-center  text-2xl lg:text-3xl xl:text-3xl text-primary-light capitalize font-semibold text-nowrap'>Grades Of Tea </h2>
                    <div className='bg-fontColor3 opacity-20 w-full h-[1px] my-5 lg:my-5'></div>
                </div>
                <p className='text-base text-center text-fontColor4 font-medium'>All You Need To Know About Your Tea Grades</p>
                <p className='text-fontColor3 text-center lg:px-10 mt-5 text-sm xl:text-base'>Tea is one of the most widely consumed beverages in the world, and it comes in a variety of grades. The grade of tea refers to the size and quality of the tea leaves. Understanding tea grades can help you choose the right tea for your taste and preferences. In this blog, we'll explore the different grades of tea and what they mean.</p>

                {data.map((item, index) => (
                    <>
                        <div key={index} className='relative mt-20'>
                            <h2 className='text-primary-light font-semibold lg:text-xl xl:text-2xl mb-5 capitalize'>{item.title}</h2>
                            <div className='absolute -top-8'>
                                <img src={item.titleImg} alt="title count" />
                            </div>
                        </div>
                        <div className='overflow-auto text-sm lg:text-base'>
                            <table className=" border-separate border-spacing-0 rounded-tl-[80px] rounded-br-[80px] w-full border-2 bg-white ">
                                <thead>
                                    <tr>
                                        <th className="px-4 py-4 border-b-2 border-r-2 min-w-[200px] max-w-full">Grade</th>
                                        <th className="px-4 py-4 border-b-2 min-w-[300px] max-w-full">Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {item.feature.map((eachFeature, j) => (
                                        <tr key={j} className="border-2 border-gray-200">
                                            <td className="text-sm xl:text-base border-b-2 px-4 py-3 border-r-2 text-center">{eachFeature.grade}</td>
                                            <td className="text-sm xl:text-base border-b-2 lg:ps-20 px-4 py-2">{eachFeature.description}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                ))}
            </div >
        </>
    )
}

export default GradesOfTea
