import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS for default styles
import React, { useEffect, useMemo, useState } from 'react';
import cherry from '../../assets/hero-section/image 52.png';
import leaf1 from '../../assets/hero-section/mint-leaf.png';
import leaf2 from '../../assets/hero-section/image 67.png';
import leaf3 from '../../assets/hero-section/image 68.png';
import cherry1 from '../../assets/hero-section/image 55.png';
import eclipse from '../../assets/hero-section/Ellipse 88041.png';
import eclipse2 from '../../assets/hero-section/Ellipse 88051.png';
import eclipse3 from '../../assets/hero-section/Ellipse 8808.png';
import eclipse4 from '../../assets/hero-section/Ellipse 8807.png';

function Sample2() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [data, setData] = useState({
        heading: '',
        paragraph: '',
        toprightimg: '',
        bottomleftimg: '',
        eclipse3: '',
        eclipse4: '',
        rightcherry1: '',
        rightcherry2: '',
        leaf1: '',
        leaf2: '',
        leaf3: '',
    });

    const items = useMemo(() => [
        {
            heading: 'Indulge in Homemade Goodness',
            paragraph: 'Each tea purchased comes with organically and ethically grown loose leaf and handpicked flowers carefully blended to create the perfect cup of tea everyday.',
            toprightimg: eclipse,
            bottomleftimg: eclipse2,
            eclipse3: eclipse3,
            eclipse4: eclipse4,
            rightcherry1: cherry1,
            rightcherry2: cherry,
            leaf1: leaf1,
            leaf2: leaf2,
            leaf3: leaf3,
        },
    ], []);

    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: true,
        });
    }, []);

    useEffect(() => {
        setData(items[currentIndex]);
        setTimeout(() => {
            AOS.refresh();
        }, 0);
    }, [currentIndex, items]);

    return (
        <>
            <div className='relative lg:pt-32 h-screen'>
                <div className='-z-10 absolute top-0 right-0'>
                    <img className='w-[250px] md:w-[600px] lg:w-[630px] xl:w-[800px]' src={data.toprightimg} alt="eclipse" />
                </div>
                <div className='absolute bottom-0 left-0'>
                    <img className='w-[300px] md:w-[800px] lg:w-[450px] xl:w-[600px] h-[200px]' src={data.bottomleftimg} alt="eclipse 2" />
                </div>
                <div id="hero" className='z-40 px-[5%] xl:px-[8%] py-5 lg:py-14 xl:pb-28 relative '>
                    <div className='grid grid-cols-12 gap-5  relative '>
                        <div className='col-span-12 lg:col-span-5 relative'>
                            <div className='mt-16 lg:mt-5'>
                                <div className='absolute -z-10 hidden lg:block bottom-20 -left-10'>
                                    <img className='w-[350px]' src={data.eclipse3} alt="eclipse 3" />
                                </div>
                                <div className='absolute -z-10 hidden lg:block -top-32 right-10 '>
                                    <img className='w-[350px]' src={data.eclipse4} alt="eclipse 4" />
                                </div>
                                <h1 className='text-3xl md:text-4xl  lg:text-3xl xl:text-4xl md:w-[55%] lg:w-[80%] font-bold text-primary-light lg:leading-[30px] xl:leading-[40px] 2xl:leading-[50px]'>{data.heading}</h1>
                                <p className='text-base lg:text-sm mt-3 xl:text-base md:w-[70%] lg:w-[80%] text-fontColor mb-3 lg:leading-[25px] xl:leading-[25px] 2xl:leading-[35px]'>{data.paragraph}</p>
                                <div className='mt-8' >
                                    <button className='capitalize bg-primary-light text-white text-sm lg:text-base rounded-full px-7 p-2'>Explore now</button>
                                </div>
                            </div>
                            <div className='relative lg:pb-5 '>
                                <div className='absolute -right-10 lg:-top-20 xl:-top-36'>
                                    <img data-aos="zoom-in" data-aos-duration="2000" className='w-[100px] md:w-[150px] lg:w-[100px] xl:w-[150px]' src={data.leaf1} alt="leaf1" />
                                </div>
                                <div className='absolute hidden lg:block -top-20 lg:-top-16 xl:-top-16 2xl:-top-20 lg:-right-20'>
                                    <img data-aos="fade-right" data-aos-duration="2000" className='h-[200px] lg:h-[200px] xl:h-[250px] w-[300px] lg:w-[450px]  xl:w-[450px]' src={data.rightcherry1} alt="cherry1" />
                                </div>
                            </div>
                        </div>
                        <div className='col-span-12 lg:col-span-7 relative'>
                            <div className='flex justify-center'>
                                <div className='relative -top-10'>
                                    <div className='absolute lg:left-28 xl:left-36 hidden md:block '>
                                        <img data-aos="zoom-in" data-aos-duration="2000" className='w-[70px] md:w-[150px] lg:w-[100px] xl:w-[100px]' src={data.leaf2} alt="leaf2" />
                                    </div>
                                    <div className='relative top-28 lg:top-14 xl:top-14'>
                                        <img data-aos="fade-left" data-aos-duration="2000" className='hidden lg:block w-[250px] lg:w-[400px] xl:w-[450px]' src={data.rightcherry2} alt="cherry" />
                                        <img className='block lg:hidden w-[500px]' src={data.rightcherry2} alt="cherry" />
                                    </div>
                                    <div className='relative top-32 lg:top-14 lg:left-24 '>
                                        <div className='flex justify-start'>
                                            <img data-aos="zoom-in" data-aos-duration="2000" className='w-[70px] md:w-[150px] lg:w-[80px] xl:w-[100px]' src={data.leaf3} alt="leaf3" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Sample2;
