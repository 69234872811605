import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS for default styles
import React, { useEffect, useState, useMemo } from 'react';
import bgTop from '../../assets/hero-section/herotop3.png'
import bgBottom from '../../assets/hero-section/Ellipse 8805.png'
import eclipse3 from '../../assets/hero-section/Ellipse 8808.png';
import eclipse4 from '../../assets/hero-section/Ellipse 8807.png';
import teaCup from '../../assets/hero-section/teacup.png';
import leaf from '../../assets/hero-section/mint-leaf.png';
import leaf1 from '../../assets/hero-section/leaf.png';
import leaf2 from '../../assets/hero-section/leaf.png';
import leaf3 from '../../assets/hero-section/leaf.png';

function Sample4() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [data, setData] = useState({
        heading: '',
        paragraph: '',
        toprightimg: '',
        bottomleftimg: '',
        eclipse3: '',
        eclipse4: '',
        rightcherry1: '',
        rightcherry2: '',
        leaf1: '',
        leaf2: '',
        leaf3: '',
    });

    const items = useMemo(() => [
        {
            heading: 'Discover the World of Tea',
            paragraph: 'Each tea purchased comes with organically and ethically groewn loose leaf and handpicked flowers carefully blended to create the perfect cup of tea everyday',
            toprightimg: bgTop,
            bottomleftimg: bgBottom,
            eclipse3: eclipse3,
            eclipse4: eclipse4,
            rightImage: teaCup,
            leftImage: leaf,
            leaf1: leaf1,
            leaf2: leaf2,
            leaf3: leaf3,
        },
    ], []);

    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: true,
        });
    }, []);

    useEffect(() => {
        setData(items[currentIndex]);
        setTimeout(() => {
            AOS.refresh();
        }, 0);
    }, [currentIndex, items]);


    return (
        <>

            <div className='relative  lg:pt-32 h-screen'>
                <div className='-z-10 absolute top-0 right-0'>
                    <img className='w-[250px] md:w-[600px] lg:w-[630px] xl:w-[800px]' src={data.toprightimg} alt="eclipse" />
                </div>
                <div className='absolute bottom-0 left-0'>
                    <img className='w-[300px] md:w-[800px] lg:w-[450px] xl:w-[600px] h-[200px]' src={data.bottomleftimg} alt="eclipse 2" />
                </div>

                <div id="hero" className='z-40 px-[5%] xl:px-[8%] py-14 lg:py-14  relative '>
                    <div className='grid grid-cols-12 gap-5 relative '>
                        <div className='col-span-12 lg:col-span-5 relative'>
                            <div className='xl:mt-10'>
                                <div className='absolute -z-10 hidden lg:block bottom-20 -left-10'>
                                    <img className='w-[350px]' src={data.eclipse3} alt="eclipse 3" />
                                </div>
                                <div className='absolute -z-10 hidden lg:block -top-32 right-10 '>
                                    <img className='w-[350px]' src={data.eclipse4} alt="eclipse 4" />
                                </div>
                                <h1 className='text-3xl md:text-4xl  lg:text-3xl xl:text-4xl md:w-[55%] lg:w-[80%] font-bold text-primary-light lg:leading-[30px] xl:leading-[40px] 2xl:leading-[50px]'>{data.heading}</h1>
                                <p className='text-base lg:text-sm mt-3 xl:text-base md:w-[70%] lg:w-[80%] text-fontColor mb-3 lg:leading-[25px] xl:leading-[25px] 2xl:leading-[35px]'>{data.paragraph}</p>
                                <div className='mt-5' >
                                    <button className='capitalize bg-primary-light text-white text-sm lg:text-base rounded-full px-7 p-2'>Explore now</button>
                                </div>
                            </div>
                            <div className='relative'>
                                <div className='absolute right-0 -top-36'>
                                    <img data-aos="zoom-in" data-aos-duration="2000" className='w-[50px] md:w-[150px] lg:w-[100px]' src={data.leaf1} alt="leaf1" />
                                </div>
                                <div className='absolute lg:block hidden  xl:-top-0 2xl:top-10 lg:right-20 xl:-right-10 2xl:right-5'>
                                    <img data-aos="fade-right" data-aos-duration="2000" className='h-[200px] lg:h-[200px] xl:h-[200px] w-[300px] lg:w-[300px] xl:w-[350px]' src={data.leftImage} alt="cherry1" />
                                </div>
                            </div>
                        </div>
                        <div className='col-span-12 lg:col-span-7 relative mt-10 lg:mt-0'>
                            <div className='flex justify-center'>
                                <div className='relative -top-10'>
                                    <div className='relative'>
                                        <div className='absolute top-20 right-0 md:-right-20'>
                                            <img data-aos="zoom-in" data-aos-duration="2000" className='w-[70px] md:w-[100px] lg:w-[130px] xl:w-[130px]' src={data.leaf3} alt="leaf3" />
                                        </div>
                                        <div className='absolute left-0 md:-left-20 top-32 lg:top-0 '>
                                            <img data-aos="zoom-in" data-aos-duration="2000" className='w-[70px] md:w-[100px] lg:w-[70px]' src={data.leaf3} alt="leaf3" />
                                        </div>
                                    </div>
                                    <div className='relative mt-20 lg:mt-0 xl:mt-10'>
                                        <img data-aos="fade-left" data-aos-duration="2000" className='hidden lg:block w-[300px] lg:w-[350px] xl:w-[450px] lg:h-[400px] xl:h-[440px]' src={data.rightImage} alt="cherry" />
                                        <img className='block lg:hidden w-[300px] md:w-[500px]' src={data.rightImage} alt="cherry" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Sample4;
