import React from 'react'
import teastory1 from '../../assets/tea-story/teastory1.png'
import teastory2 from '../../assets/tea-story/teastory2.png'
import teastory3 from '../../assets/tea-story/teastory3.png'
import teastory4 from '../../assets/tea-story/teastory4.png'
import teastory5 from '../../assets/tea-story/teastory5.png'
import teastory6 from '../../assets/tea-story/teastory6.png'
import one from '../../assets/tea-story/01.png'
import two from '../../assets/tea-story/02.png'
import three from '../../assets/tea-story/03.png'
import four from '../../assets/tea-story/04.png'
import five from '../../assets/tea-story/05.png'
import six from '../../assets/tea-story/06.png'
import Conclusion from '../../assets/tea-story/Conclusion.png'
import GradesOfTea from './GradesOfTea'
import ScrollToTopButton from '../../components/scrolltotop/ScrollToTopButton'

function TeaStoryIndex() {

    const data = [
        {
            imgUrl: teastory1,
            title: 'Tulsi (Holy Basil) Tea',
            titleImg: one,
            heading1: "Why It's Beneficial:",
            description1: "Tulsi, also known as Holy Basil, is revered in Ayurveda for its medicinal properties. It is rich in antioxidants and has antibacterial, antiviral, and anti-inflammatory properties, making it an excellent immune booster.",
            heading2: "How to Prepare",
            description2: "Steep a handful of fresh tulsi leaves or a teaspoon of dried leaves in hot water for 5-10 minutes. Strain and enjoy with a dash of honey for added flavor and benefits.",
        },
        {
            imgUrl: teastory2,
            title: 'Ginger Tea',
            titleImg: two,
            heading1: "Why It's Beneficial:",
            description1: "Ginger is known for its anti-inflammatory and antioxidant properties. It helps in relieving throat irritation, congestion, and digestive issues, which are common during the monsoon season.",
            heading2: "How to Prepare",
            description2: "Boil a few slices of fresh ginger in water for 10 minutes. Strain and add honey or lemon juice to taste. Enjoy this soothing tea warm.",
        },
        {
            imgUrl: teastory3,
            title: 'Turmeric Tea',
            titleImg: three,
            heading1: "Why It's Beneficial:",
            description1: "Turmeric contains curcumin, a compound with powerful anti-inflammatory and antioxidant effects. It helps in boosting the immune system and fighting off infections.",
            heading2: "How to Prepare",
            description2: "Mix a teaspoon of turmeric powder or freshly grated turmeric in hot water. Add a pinch of black pepper (to enhance curcumin absorption) and a teaspoon of honey. Stir well and sip slowly",
        },
        {
            imgUrl: teastory4,
            title: 'Peppermint Tea',
            titleImg: four,
            heading1: "Why It's Beneficial:",
            description1: "Peppermint has menthol, which acts as a natural decongestant and helps soothe sore throats and relieve congestion. It also has antiviral and antimicrobial properties.",
            heading2: "How to Prepare",
            description2: "Steep a teaspoon of dried peppermint leaves or a handful of fresh leaves in hot water for 5-7 minutes. Strain and enjoy hot or cold.",
        },
        {
            imgUrl: teastory5,
            title: 'Lemongrass Tea',
            titleImg: five,
            heading1: "Why It's Beneficial:",
            description1: "Lemongrass is rich in antioxidants and has antimicrobial properties. It aids in detoxification and helps strengthen the immune system.",
            heading2: "How to Prepare",
            description2: "Chop fresh lemongrass stalks and boil in water for 5-10 minutes. Strain and add honey or lemon for extra flavor. Drink warm for best results.",
        },
        {
            imgUrl: teastory6,
            title: 'Chamomile Tea',
            titleImg: six,
            heading1: "Why It's Beneficial:",
            description1: "Chamomile is known for its calming effects and helps in reducing stress and anxiety, which can weaken the immune system. It also has mild antimicrobial properties.",
            heading2: "How to Prepare",
            description2: "Steep a teaspoon of dried chamomile flowers in hot water for 5 minutes. Strain and enjoy before bedtime for a restful sleep and immune support.",
        },
    ]

    return (
        <>
            <div className='relative'>
                <div className='bg-teaBanner -z-10 absolute  bg-no-repeat w-full bg-cover h-52 lg:h-[65vh] xl:h-[80vh]'>
                </div>
                <div className=' z-50 px-[5%] xl:px-[8%] mb-5 md:mb-0 md:py-10 pt-[150px] lg:pt-[300px] xl:pt-[450px]'>
                    <div className='bg-background6 rounded-t-3xl px-5 lg:px-10 pt-5 lg:pb-10'>
                        <div className='py-5'>
                            <h2 className='text-center  text-2xl lg:text-3xl xl:text-3xl text-primary-light capitalize font-semibold'>Tea story</h2>
                            <div className='bg-fontColor3 opacity-20 w-full h-[1px] my-2 lg:my-5'></div>
                            <p className='text-base lg:text-xl xl:text-2xl text-center text-fontColor4 font-medium mb-5'>Herbal Teas Boost Immunity During Monsoons</p>
                            <h3 className='capitalize font-medium text-primary-light text-base lg:text-xl mb-3'>introduction</h3>
                            <p className='text-fontColor3 text-sm xl:text-base'>The monsoon season brings relief from the scorching summer heat but also poses challenges to our immune system. The increased humidity and fluctuating temperatures can make us more susceptible to infections and illnesses. Fortunately, nature offers a simple and delicious way to bolster our defenses: herbal teas. Packed with antioxidants, vitamins, and immune-boosting properties, herbal teas are a perfect companion during the monsoon. Let's explore some of the best herbal teas to boost your immunity during this season.</p>
                        </div>
                        <div className='grid grid-cols-12 gap-4 lg:gap-10 py-10'>
                            {data.map((item, i) => (
                                <React.Fragment key={i}>
                                    {((i + 1) % 2 === 0) ? (
                                        <>
                                            <div className='col-span-12 lg:col-span-4 hidden lg:block lg:mb-10'>
                                                <img src={item.imgUrl} alt="" />
                                            </div>
                                            <div className='col-span-12 lg:col-span-8 lg:pe-32 mt-10 lg:mt-0'>
                                                <div className='relative '>
                                                    <h2 className='text-primary-light font-semibold  text-xl xl:text-2xl mb-3 lg:mb-10 capitalize'>{item.title}</h2>
                                                    <div className='absolute -top-8'>
                                                        <img src={item.titleImg} alt="title count" />
                                                    </div>
                                                </div>
                                                <h6 className='text-fontColor4 text-base lg:text-xl font-medium mb-1 lg:mb-3'>{item.heading1}</h6>
                                                <p className='text-fontColor3 text-justify text-sm xl:text-base mb-5 lg:mb-10'>{item.description1}</p>
                                                <h6 className='text-fontColor4 text-base font-medium mb-1 lg:mb-3'>{item.heading2}</h6>
                                                <p className='text-fontColor3 text-sm xl:text-base'>{item.description2}</p>
                                                <img className='object-cover lg:hidden mt-5' src={item.imgUrl} alt="" />
                                            </div>
                                        </>
                                    ) : (
                                        <>

                                            <div className='col-span-12 lg:col-span-8 lg:pe-32 mt-10 lg:mt-0 lg:mb-10'>
                                                <div className='relative '>
                                                    <h2 className='text-primary-light font-semibold  text-xl xl:text-2xl mb-3 lg:mb-10 capitalize'>{item.title}</h2>
                                                    <div className='absolute -top-8'>
                                                        <img src={item.titleImg} alt="title count" />
                                                    </div>
                                                </div>
                                                <h6 className='text-fontColor4 text-base lg:text-xl font-medium mb-1 lg:mb-3'>{item.heading1}</h6>
                                                <p className='text-fontColor3 text-justify text-sm xl:text-base mb-5 lg:mb-10'>{item.description1}</p>
                                                <h6 className='text-fontColor4 text-base font-medium mb-1 lg:mb-3'>{item.heading2}</h6>
                                                <p className='text-fontColor3 text-sm xl:text-base'>{item.description2}</p>
                                            </div>
                                            <div className='col-span-12 lg:col-span-4'>
                                                <img src={item.imgUrl} alt="" />
                                            </div>
                                        </>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                        <div className='grid grid-cols-12 gap-5 py-10'>
                            <div className='col-span-12 lg:col-span-8 lg:mt-3'>
                                <div className='relative'>
                                    <h2 className='text-primary-light font-semibold  text-xl xl:text-2xl mb-3 lg:mb-10 capitalize '>Conclusion</h2>
                                </div>
                                <h6 className='text-fontColor4 text-base lg:text-xl font-medium mb-1 lg:mb-3'>Sip Your Way to Better Health</h6>
                                <p className='text-fontColor3 text-justify text-sm xl:text-base mb-5 lg:mb-10'>Incorporating herbal teas into your daily routine during the monsoon season is a delightful and effective way to boost your immunity. These teas not only provide warmth and comfort but also deliver a range of health benefits that can help protect you from common seasonal ailments. So, brew yourself a cup of herbal goodness and enjoy the monsoons with a fortified immune system and a healthy body.</p>
                            </div>
                            <div className='col-span-12 lg:col-span-4'>
                                <img src={Conclusion} alt="" />
                            </div>
                        </div>
                    </div>
                    <GradesOfTea />
                </div>
            </div>
            <ScrollToTopButton />
        </>
    )
}

export default TeaStoryIndex
