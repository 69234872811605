import React, { useState } from 'react'
import { RiEditLine } from "react-icons/ri";
import { FaPlus } from "react-icons/fa6";
import { Radio } from 'flowbite-react';
import AddressForm from './AddressForm';

function MyAddressIndex({ nextStep }) {
    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const data = [
        {
            address: "Site no 24, HighWayCity, Sarkarsamakulam, Kovilpalayam, Coimbatore 641107",
        },
        {
            address: "4th Floor, 16th Main N. S. Palya, near by to, Mico Layout, BTM 2nd Stage, Bengaluru, Karnataka 560076",
        },
    ]

    return (
        <>
            <div className='bg-white p-5 border-2 rounded-b-[40px] rounded-tr-[40px]'>
                <h4 className='text-background2 font-medium mb-1 text-xl capitalize '>my address</h4>
                <div className='w-full h-[1px] bg-gray-300'></div>
                {data.map((item, i) => (
                    <>
                        <div key={i} className='flex items-start mt-5'>
                            <div className='mt-1 me-4'>
                                <Radio id="united-state" name="countries" value="USA" defaultChecked />
                            </div>
                            <div className=''>
                                <div className='flex justify-between items-start mb-5 text-base text-fontColor3'>
                                    <p>{item.address}</p>
                                    <div className='flex items-center text-background2'>
                                        <p>Edit</p>
                                        <RiEditLine />
                                    </div>
                                </div>
                                <div className='w-full h-[1px] bg-gray-300'></div>
                            </div>
                        </div>
                    </>
                ))}
                <div className='mt-5'>
                    <button onClick={openModal} className='flex justify-center items-center capitalize text-primary-light border-2 border-primary-light px-2 py-1 rounded-3xl gap-1 '><FaPlus />add address</button>
                </div>
                <div className='w-full h-[1px] bg-gray-300 my-3'></div>
                <div className='flex justify-end'>
                    <button onClick={nextStep} className='bg-background2 text-white px-9 py-1 rounded-3xl capitalize'>next</button>
                </div>
            </div>
            <AddressForm isOpen={isModalOpen} onClose={closeModal} />
        </>
    )
}

export default MyAddressIndex
