import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS for default styles
import React, { useEffect, useState, useMemo } from 'react';
import bgTop from '../../assets/hero-section/hero5.png'
import bgBottom from '../../assets/hero-section/Ellipse 8805.png'
import eclipse3 from '../../assets/hero-section/Ellipse 8808.png';
import eclipse4 from '../../assets/hero-section/Ellipse 8807.png';
import teaCup from '../../assets/hero-section/image 64.png';
import leaf from '../../assets/hero-section/image 63.png';
import leaf1 from '../../assets/hero-section/image 75.png';
import leaf2 from '../../assets/hero-section/image 75.png';
import leaf3 from '../../assets/hero-section/image 75.png';

function Sample5() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [data, setData] = useState({
        heading: '',
        paragraph: '',
        toprightimg: '',
        bottomleftimg: '',
        eclipse3: '',
        eclipse4: '',
        rightImage: '',
        leftImage: '',
        leaf1: '',
        leaf2: '',
        leaf3: '',
    });

    const items = useMemo(() => [
        {
            heading: 'Discover the World of Tea',
            paragraph: 'Each tea purchased comes with organically and ethically grown loose leaf and handpicked flowers carefully blended to create the perfect cup of tea everyday',
            toprightimg: bgTop,
            bottomleftimg: bgBottom,
            eclipse3: eclipse3,
            eclipse4: eclipse4,
            rightImage: teaCup,
            leftImage: leaf,
            leaf1: leaf1,
            leaf2: leaf2,
            leaf3: leaf3,
        },
    ], []);

    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: true,
        });
    }, []);

    useEffect(() => {
        setData(items[currentIndex]);
        setTimeout(() => {
            AOS.refresh();
        }, 0);
    }, [currentIndex, items]);

    return (
        <div className='relative lg:pt-32 h-screen'>
            <div className='-z-10 absolute top-0 right-0'>
                <img className='w-[250px] md:w-[600px] lg:w-[630px] xl:w-[800px]' src={data.toprightimg} alt="eclipse" />
            </div>
            <div className='absolute bottom-0 left-0'>
                <img className='w-[300px] md:w-[800px] lg:w-[450px] xl:w-[600px] h-[200px]' src={data.bottomleftimg} alt="eclipse 2" />
            </div>
            <div id="hero" className='z-40 px-[5%] xl:px-[8%] py-10 lg:py-14  relative '>
                <div className='grid grid-cols-12 gap-5 relative '>
                    <div className='col-span-12 lg:col-span-5 relative'>
                        <div className='mt-10'>
                            <div className='absolute -z-10 hidden lg:block bottom-20 -left-10'>
                                <img className='w-[350px]' src={data.eclipse3} alt="eclipse 3" />
                            </div>
                            <div className='absolute -z-10 hidden lg:block -top-32 right-10 '>
                                <img className='w-[350px]' src={data.eclipse4} alt="eclipse 4" />
                            </div>
                            <h1 className='text-3xl md:text-4xl  lg:text-3xl xl:text-4xl md:w-[55%] lg:w-[80%] font-bold text-primary-light lg:leading-[30px] xl:leading-[40px] 2xl:leading-[50px]'>{data.heading}</h1>
                            <p className='text-base lg:text-sm mt-3 xl:text-base md:w-[70%] lg:w-[80%] text-fontColor mb-3 lg:leading-[25px] xl:leading-[25px] 2xl:leading-[35px]'>{data.paragraph}</p>
                            <div className='mt-8' >
                                <button className='capitalize bg-primary-light text-white text-sm lg:text-base rounded-full px-7 p-2'>Explore now</button>
                            </div>
                        </div>
                        <div className='relative hidden lg:block'>
                            <div className='absolute -top-20 right-0'>
                                <img data-aos="zoom-in" data-aos-duration="2000" className='hidden lg:block w-[70px]' src={data.leaf1} alt="leaf1" />
                            </div>
                            <div className='absolute  lg:-top-0 xl:-top-10 2xl:top-10  lg:right-20'>
                                <img data-aos="fade-right" data-aos-duration="2000" className='lg:h-[150px] xl:h-[200px] w-[300px] h-[200px] lg:w-[250px] xl:w-[250px] 2xl:w-[300px]' src={data.leftImage} alt="cherry1" />
                            </div>
                            <div className='absolute lg:-left-0 xl:-left-16 lg:-bottom-20 xl:-bottom-32 '>
                                <img data-aos="zoom-in" data-aos-duration="2000" className='lg:w-[70px] xl:w-[70px]' src={data.leaf3} alt="leaf2" />
                            </div>
                        </div>
                    </div>
                    <div className='col-span-12 lg:col-span-7 relative mt-16 lg:mt-0'>
                        <div className='flex justify-center'>
                            <div className='relative -top-10'>
                                <div className='absolute lg:-right-28 xl:-right-16 xl:top-10 '>
                                    <img data-aos="zoom-in" data-aos-duration="2000" className='lg:w-[70px] xl:w-[70px]' src={data.leaf3} alt="leaf2" />
                                </div>
                                <div className='relative mt-16 lg:mt-0 xl:mt-10'>
                                    <img data-aos="fade-left" data-aos-duration="2000" className='hidden lg:block w-[500px] lg:w-[500px] xl:w-[500px]' src={data.rightImage} alt="cherry" />
                                    <img className='block lg:hidden w-[600px] h-[300px] md:h-[500px] ' src={data.rightImage} alt="cherry" />
                                </div>
                                <div className='relative top-10 lg:left-24'>
                                    <div className='flex justify-start'>
                                        <img data-aos="zoom-in" data-aos-duration="2000" className='lg:w-[70px] xl:w-[70px]' src={data.leaf3} alt="leaf3" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sample5;
