import React, { useState, useEffect } from 'react';
import { BiArrowToTop } from "react-icons/bi";


const ScrollToTopButton = () => {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <button
            onClick={scrollToTop}
            style={{
                display: showButton ? 'block' : 'none',
                position: 'fixed',
                bottom: '50px',
                right: '50px',
                zIndex: 1000,
                padding: '10px',
                backgroundColor: '#6A2314', // Customize the color as needed
                color: 'white',
                border: 'none',
                borderRadius: '20px',
                cursor: 'pointer',
            }}
        >
            <BiArrowToTop />
        </button>
    );
};

export default ScrollToTopButton;
