import React from 'react'
import { IoIosSearch } from "react-icons/io";


function SearchForm() {
    return (
        <div className=''>
            <form className='flex justify-between' action="">
                <input className='w-full p-3 rounded-md' type="text" placeholder='Search' />
                <button className='bg-background2 ms-2 p-3 px-4 text-white border-2 border-white rounded-md'><IoIosSearch /></button>
            </form>
        </div>
    )
}

export default SearchForm
