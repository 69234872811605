import React, { useState } from 'react';
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-multi-carousel';
import biscuit from '../../assets/home/Frame 1597885305 1.png';
import leaf from '../../assets/home/feedback.png';
import feedback1 from '../../assets/home/Ellipse 11.png';
import feedback2 from '../../assets/home/Ellipse 12.png';
import feedback3 from '../../assets/home/Ellipse 13.png';
import FeedBackCard from '../../components/feedBackCard/FeedBackCard';

function FeedBack() {
    const items = [
        {
            name: 'Alrin Sherbi',
            imgSrc: feedback1,
            alt: 'tea cup',
            description: '"Emerald Delight Green Tea is my daily go-to! The delicate, smooth flavor is refreshing and invigorating. I feel more energized and focused without the jitters. The health benefits are noticeable, especially for my skin and overall well-being. Highly recommend!"',
        },
        {
            name: 'Robin SD',
            imgSrc: feedback2,
            alt: 'tea leaf',
            description: 'Exceptional quality and taste! Emerald Delight stands out with its pure, natural sweetness and fresh, grassy aroma. It’s my favorite way to start the morning, offering a perfect balance of flavor and health benefits. Truly exceptional!',
        },
        {
            name: 'Shibi TL',
            imgSrc: feedback3,
            alt: 'cookie',
            description: '"A tea lover’s dream! Aromatic, flavorful, and packed with antioxidants. Helps me reduce my caffeine intake from coffee, and I feel much better overall. Beautiful packaging makes it a perfect gift. This tea is truly a delight!"',
        },
    ];

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 3,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    const [currentSlide, setCurrentSlide] = useState(0);

    const getCenterIndex = (currentSlide, totalItems) => {
        return Math.floor(currentSlide + totalItems / 2) % totalItems;
    };

    return (
        <div className='px-[5%] xl:px-[8%] py-5 lg:py-16 relative'>
            <h2 className='capitalize font-bold text-primary-light text-center text-xl lg:text-2xl xl:text-3xl mb-3 lg:mb-5'>Customer’s Love</h2>
            <p className='text-paragraph text-center text-xs lg:text-sm xl:text-base lg:mb-14'>What Our Customers Are Saying</p>
            <Carousel
                responsive={responsive}
                ssr={true} // means to render carousel on server-side
                infinite={true}
                autoPlay={true}
                swipeable={true}
                draggable={true}
                arrows={false}
                autoPlaySpeed={2000}
                keyBoardControl={true}
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                itemClass="carousel-item-padding-40-px"
                showDots={false}
                dotListClass="custom-dot-list"
                beforeChange={(nextSlide) => setCurrentSlide(nextSlide)}
            >
                {items.map((item, index) => (
                    <FeedBackCard
                        key={index}
                        item={item}
                        isCenter={index === getCenterIndex(currentSlide, items.length)}
                    />
                ))}
            </Carousel>
            <div className='absolute bottom-20 left-0 -z-10'>
                <img className='w-[100px] lg:w-auto' src={leaf} alt="leaf" />
            </div>
            <div className='absolute bottom-24 lg:bottom-16 right-10'>
                <img className='w-[130px] lg:w-[250px] xl:w-[300px] h-[150px] md:h-[130px] lg:h-[200px] xl:h-[250px]' src={biscuit} alt="biscuit" />
            </div>
        </div>
    );
}

export default FeedBack;
