import React from 'react';
import star from '../../assets/home/Star 1.png';

function FeedBackCard({ item, isCenter }) {
    return (
        <div className={`relative mx-5 md:mx-10 h-[100%] px-5 bg-white rounded-[40px] border-2 ${isCenter ? 'lg:mt-20' : ''}`}>
            <div className="flex justify-around">
                <div className="absolute -top-24 flex justify-around">
                    <img src={item.imgSrc} alt={item.alt} className="object-contain h-[150px]" />
                </div>
                <div className='pt-20'>
                    <div className="mb-3">
                        <p className="font-semibold text-center capitalize">{item.name}</p>
                        <p className="flex justify-center">
                            <img className="ms-1 w-[15px] h-[15px] md:w-[20px] md:h-[20px]" src={star} alt="star" />
                            <img className="ms-1 w-[15px] h-[15px] md:w-[20px] md:h-[20px]" src={star} alt="star" />
                            <img className="ms-1 w-[15px] h-[15px] md:w-[20px] md:h-[20px]" src={star} alt="star" />
                            <img className="ms-1 w-[15px] h-[15px] md:w-[20px] md:h-[20px]" src={star} alt="star" />
                            <img className="ms-1 w-[15px] h-[15px] md:w-[20px] md:h-[20px]" src={star} alt="star" />
                        </p>
                    </div>
                    <p className="text-paragraph text-center text-[12px] xl:text-[15px] pb-5">{item.description}</p>
                </div>
            </div>
        </div>
    );
}

export default FeedBackCard;
