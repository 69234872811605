import React from 'react'
import { MdOutlineLocalPhone } from "react-icons/md";

function ContactDetails() {
    return (
        <>
            <div className='lg:absolute -bottom-12 pt-10 lg:pt-0'>
                <div className='relative'>
                    <div className='px-10 w-full flex flex-col lg:gap-16 xl:gap-16 2xl:gap-24 lg:flex-row gap-y-5 justify-between py-5 bg-white rounded-tl-3xl rounded-br-3xl border-[1px] border-primary-light'>
                        <div className='flex flex-col justify-center items-center mb-5 lg:mb-0'>
                            <p className='text-sm lg:text-sm xl:text-base font-medium'>For Quality or Feedback Queries</p>
                            <p className='flex items-center text-fontColor3 text-sm xl:text-base mt-2'><MdOutlineLocalPhone className='me-1' />+91 9961241155</p>
                        </div>
                        <div className='flex flex-col justify-center items-center mb-5 lg:mb-0'>
                            <p className='text-sm lg:text-sm xl:text-base font-medium'>Marketing</p>
                            <p className='flex items-center text-fontColor3 text-sm xl:text-base mt-2'><MdOutlineLocalPhone className='me-1' />+91 9597847400</p>
                        </div>
                        <div className='flex flex-col justify-center items-center mb-5 lg:mb-0'>
                            <p className='text-sm lg:text-sm xl:text-base font-medium'>Kerala</p>
                            <p className='flex items-center text-fontColor3 text-sm xl:text-base mt-2'><MdOutlineLocalPhone className='me-1' />+91 9961241155</p>
                        </div>
                        <div className='flex flex-col justify-center items-center mb-5 lg:mb-0'>
                            <p className='text-sm lg:text-sm xl:text-base font-medium'>Tamilnadu</p>
                            <p className='flex items-center text-fontColor3 text-sm xl:text-base mt-2'><MdOutlineLocalPhone className='me-1' />+91 9787591023</p>
                        </div>
                        <div className='flex flex-col justify-center items-center mb-5 lg:mb-0'>
                            <p className='text-sm lg:text-sm xl:text-base font-medium'>Karnataka</p>
                            <p className='flex items-center text-fontColor3 text-sm xl:text-base mt-2'><MdOutlineLocalPhone className='me-1' />+91 9916355135</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactDetails
