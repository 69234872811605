import React, { useEffect } from 'react';
import SearchForm from './SearchForm';

const SearchIndex = ({ isOpen, onClose, children }) => {

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" onClick={onClose}>
            <div className="rounded-lg shadow-lg w-11/12 md:w-1/3" onClick={(e) => e.stopPropagation()}>
                <div className="flex justify-end p-2">
                    {/* <button
                        className="text-gray-700 hover:text-gray-900"
                        onClick={onClose}
                    >
                        &times;
                    </button> */}
                </div>
                <div className="p-4">
                    <SearchForm />
                </div>
            </div>
        </div>
    );
};

export default SearchIndex;
