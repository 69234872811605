import React from 'react'
import offer from '../../assets/home/Group 9.png'

function SpecialOffer() {
    return (
        <>
            <div className='px-[5%] xl:px-[8%] py-10 lg:py-24 bg-background '>
                <div className='flex flex-col md:flex-row gap-y-5 justify-around'>
                    <div className='flex justify-center'>
                        <img className='md:h-[280px] lg:h-[400px]' src={offer} alt="offer" />
                    </div>
                    <div className=''>
                        <div className='bg-background4  rounded-3xl p-8 lg:p-20  lg:h-[400px]'>
                            <h2 className='capitalize font-semibold text-fontColor2 text-xl lg:text-2xl xl:text-3xl mb-5 lg:mb-14 '>Special offer</h2>
                            <p className='text-sm lg:text-2xl xl:text-3xl mb-3 md:mb-8 leading-10'>20 % Cash-Back on  Premium Tea</p>
                            <p className='mb-5 md:mb-10'>Above 1000</p>
                            <button className='border-2 border-solid border-primary-light text-primary-light px-8 py-1 rounded-full'>SHOP NOW</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SpecialOffer
